// @<COPYRIGHT>@
// ==================================================
// Copyright 2018.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

import $ from 'jquery';
import _ from 'lodash';
import prefSvc from "soa/preferenceService";
import _appCtxService from "js/appCtxService";
import _soaSvc from "soa/kernel/soaService";
import _awSearchService from 'js/awSearchService';
import _vmoSvc from 'js/viewModelObjectService';
import _cdm from 'soa/kernel/clientDataModel';
import _uwPropertySvc from 'js/uwPropertyService';
import _appCtxSvc from 'js/appCtxService';
import eventBus from 'js/eventBus';
import propPolicySvc from 'soa/kernel/propertyPolicyService';

let exports = {};

export let OpenSchedulingCockpitLinkAction = function (selected) {
  prefSvc
    .getStringValue("G4B_SchedulingCockpit_URL")
    .then(function (prefValue) {
      let der, bp, sop;
      der = selected.props.g4b_DER.dbValues[0];
      bp = selected.props.g4b_BP.dbValues[0];
      sop = selected.props.g4b_SOP.dbValues[0];

      sop = sop.slice(0, 2) + "-" + sop.slice(2);

      prefValue = prefValue.replace("<bp>", bp);
      prefValue = prefValue.replace("<der>", der);
      prefValue = prefValue.replace("<sop>", sop);

      if (!prefValue || prefValue.length <= 0) {
        prefValue = "";
      }
      window.open(prefValue);
    });
};

export let getInternalPropertyName = function () {
  return _awSearchService.getInternalPropertyName();
};

export const g4bProcessOutput = (response, dataCtxNode, searchData) => {
  let cDObj = [];
  let filteredCD = [];
  let selectedTS = _appCtxService.getCtx('selected');
  _.forEach(response.ServiceData.modelObjects, function (object) {
      if (object.type === "G4B_CollaborativeDesign") {
          cDObj.push(object);
      }
  });
  for (let inx = 0; inx < cDObj.length; inx++) {
      if (cDObj[inx].props.g4b_TSInfos2.dbValues.length > 0) {
          for (let i = 0; i < cDObj[inx].props.g4b_TSInfos2.dbValues.length; i++) {
              if (cDObj[inx].props.g4b_TSInfos2.dbValues[i] == selectedTS.uid) {
                  filteredCD.push(cDObj[inx]);
              }
          }
      }
  }
  return filteredCD;
};


export let loadColumnsForCdObjects = function (dataProvider) {
  dataProvider.columnConfig = {
      columns: [
          {
              name: "object_name",
              displayName: "Name",
              typeName: "WorkspaceObject",
              width: 400,
          }
      ],
  };
};

exports = {
  OpenSchedulingCockpitLinkAction,
  getInternalPropertyName,
  g4bProcessOutput,
  loadColumnsForCdObjects
};

export default exports;
