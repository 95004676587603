// @<COPYRIGHT>@
// ==================================================
// Copyright 2017.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/*global
 define
 */

/**
 * This Service is for CreateFTMIssue.
 *
 * @module js/CreateFTMIssueService
 *
 * ModifyBy             ModifyOn           Comment
 * Niranjan Rekhate     26-June-2020       Added PropertyPolicy
 * Anantha Bhople       01-July-2020       Added function to update already attached forms attribute "v4b_ZBS_INST_ID" in teamcenter. Function call on FTM Issue duplicate action.
 */
import app from "app";
import _ from "lodash";
import "soa/kernel/clientDataModel";
import soaSvc from "soa/kernel/soaService";
import appCtxSvc from "js/appCtxService";
import VIMSoaUtils from "js/VIMSoaUtils";
import propPolicyService from "soa/kernel/propertyPolicyService";
import messagingService from "js/messagingService";
import localeService from "js/localeService";
import AwStateService from "js/awStateService";
import AwHttpService from "js/awHttpService";
import AwPromiseService from "js/awPromiseService";
import eventBus from 'js/eventBus';

("use strict");

let exports = {};

let $http = AwHttpService.instance;
//new internal function defined here

let relationNameMap = {
  Problemelement: "V4B_Problemnode",
  Vorschlagelement: "V4B_Proposalnode",
  Loesungselement: "V4B_Solutionnode",
};

/**
 * This Function is for To set Created Status on Creation of FTM Issue.
 * @function startWorkflow
 * @param {objdata} FTMRevision - Newly created FTMIssueRevision.
 */
export let startWorkflow = function (data) {
  console.log(
    "==== created status will be applied to FTM issue on the server side. hence this code is not required!!!"
  );
  return AwPromiseService.instance.resolve({});
};

/**
 * This function is for Open newly created FTM Issue Revision in Edit mode and call to submit workflow function.
 * @function createRelateAndSubmitAction
 *
 */
export let createRelateAndSubmitAction = function (ftmtype) {
  let deferred = AwPromiseService.instance.defer();
  let parentObjectType = "V4B_FTMTKB_Issue";
  let compountCreateInputData = {
    revision: [
      {
        boName: "V4B_FTMTKB_IssueRevision",
        propertyNameValues: {
          v4b_ftm_type: [ftmtype],
          v4b_ref_genftm_approver: [
            appCtxSvc.getCtx("userSession.props.user.dbValue"),
          ],
        },
      },
    ],
  };

  let propertyPolicy = {
    types: [
      {
        name: "V4B_FTMTKB_IssueRevision",
        properties: [
          {
            name: "item_id",
          },
          {
            name: "v4b_ftm_type",
          },
          {
            name: "v4b_ref_genftm_approver",
          },
        ],
      },
    ],
  };
  propPolicyService.register(propertyPolicy);

  VIMSoaUtils.createRelateAndSubmitObjects(
    parentObjectType,
    compountCreateInputData
  ).then(function (response) {
    return deferred.resolve(response);
  });
  return deferred.promise;
};

/**
 * This function is for Open newly created FTM Issue Revision in Edit mode and call to submit workflow function.
 * @function createRelateAndSubmitResponse
 * @param {object} data output response from the service called from module.json
 */
export let createRelateAndSubmitResponse = function (data) {
  let ftmRevision = null;

  if (data.ftmRevision) ftmRevision = data.ftmRevision;
  else {
    let modelObjs = data.createFTMResponseData.ServiceData.modelObjects;
    console.log(
      "data.createFTMResponseData.ServiceData.modelObjects= " + modelObjs
    );
    console.log("data");
    console.log(data);
    // find uid in responseData modelObjects
    // let ftmRevision = null;
    // for (let key in modelObjs) {
    //     if ("V4B_FTMTKB_IssueRevision" === modelObjs[key].type)
    //         ftmRevision = modelObjs[key];
    // }
    let objects = data.createFTMResponseData.output[0].objects;
    for (let key in objects) {
      if (objects[key].type == "V4B_FTMTKB_IssueRevision")
        ftmRevision = objects[key];
    }
  }
  let showObject = "com_siemens_splm_clientfx_tcui_xrt_showObject";
  let toParams = {};
  let options = {};

  if(!ftmRevision.uid)
  {
      toParams.uid = ftmRevision.objUID;
  }
  else
  {
      toParams.uid = ftmRevision.uid;
  }
  toParams.cmdId = "Awp0StartEdit";
  options.inherit = false;
  toParams.edit = true;
  AwStateService.instance.go(showObject, toParams, options);
};
/**
 * Function for read preference and get Prisma JDBC Info like DB URL, username and password.
 * @function getPrismaJDBSinfo
 * @param {object} data - Response Data.
 */
export let getPrismaJDBSinfo = function (data) {
  let dbUrl = appCtxSvc.getCtx(
      "preferences['V4B_VIM_DiC_CATIA_INTEG_JDBC_URL']"
    ),
    username = appCtxSvc.getCtx(
      "preferences['V4B_VIM_DiC_CATIA_INTEG_SYS_USER']"
    ),
    userpassword = appCtxSvc.getCtx(
      "preferences['V4B_VIM_DiC_CATIA_INTEG_SYS_USERPASS']"
    );
    userpassword = b64EncodeUnicodeUTF8(userpassword);
  let jdbc = {
    dbUrl: dbUrl[0],
    username: username[0],
    userpassword: userpassword,
  };
  console.log("jdbc==", jdbc);
  return jdbc;
};
function b64EncodeUnicodeUTF8(str) {
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
      function toSolidBytes(match, p1) {
          return String.fromCharCode('0x' + p1);
  }));
}
let getBaseOedeInput = function (partName, documentType) {
  return {
    comment: "Aus VIM Client erzeugt",
    documentAlternative: "A",
    documentFormat: "ST",
    documentPart: 1,
    documentType: documentType,
    module: "51.00",
    partName: partName,
    projectName: "ALLGEM",
    qNumberCreator: appCtxSvc.getCtx("userSession.props.user_id.dbValue"),
    version: "A",
  };
};

let getStOedeInput = function (partName, documentType, zaRelationType) {
  let baseOede = getBaseOedeInput(partName, documentType);
  baseOede.oedeProps = {
    v4b_ZA_RELATION_TYPE: zaRelationType,
  };
  return baseOede;
};

/**
 * This function for Prepearing OED object for creation of 3D Geoemtry Dic Assignment Task Form.
 * @function getOEDEForCreate3DZA
 * @param {object} data - Response Data.
 */
export let getOEDEForCreate3DZA = function (data) {
  //ctx.userSession.props.user_id.dbValue
  let ctx = appCtxSvc.getCtx;

  let ftmRevision = null;
  if (data.ftmRevision) {
    ftmRevision = data.ftmRevision;
  } else {
    // let modelObjs = data.createFTMResponseData.ServiceData.modelObjects;
    // for (let key in modelObjs) {
    //     if (modelObjs[key].type == "V4B_FTMTKB_IssueRevision") ftmRevision = modelObjs[key];
    // }
    let objects = data.createFTMResponseData.output[0].objects;
    for (let key in objects) {
      if (objects[key].type == "V4B_FTMTKB_IssueRevision")
        ftmRevision = objects[key];
    }
  }

  let topDiCNode = getBaseOedeInput(
    ftmRevision.props.item_id.uiValues[0],
    "PACKAG"
  );
  let stConcept = getStOedeInput(
    "Reference Geometry ST",
    "ZBSPRO",
    "Problemelement"
  );
  let stProposal = getStOedeInput(
    "Proposal Geometry ST",
    "ZBSREC",
    "Vorschlagelement"
  );
  let stSolution = getStOedeInput(
    "Solution Geometry ST",
    "ZBSSOL",
    "Loesungselement"
  );

  // gather child geometry only incase of Duplicate
  if (data.duplicateFTMRevision !== undefined) {
    stConcept.childObjects = getChildGeometries(
      data.duplicateFTMRevision,
      data.allModelObjects,
      "V4B_ZA_Problem_Temp"
    );
    stProposal.childObjects = getChildGeometries(
      data.duplicateFTMRevision,
      data.allModelObjects,
      "V4B_ZA_Proposal_Temp"
    );
    stSolution.childObjects = getChildGeometries(
      data.duplicateFTMRevision,
      data.allModelObjects,
      "V4B_ZA_Solution_Temp"
    );
  }

  return [topDiCNode, stConcept, stProposal, stSolution];
};

let getChildGeometries = function (
  duplicateFTMRevision,
  allModelObjects,
  relationName
) {
  let allChildGeo = [];
  if (duplicateFTMRevision.props[relationName]) {
    allChildGeo = duplicateFTMRevision.props[relationName].dbValues.map(
      function (eachUID) {
        let zaForm = allModelObjects[eachUID];
        let objName = zaForm.props.object_name.dbValues[0].trim();
        let oedeStr = objName.split(/\s+/).splice(0, 6).join(" ");

        let matrixDbValues = zaForm.props.v4b_PRISMA_TRANS_MATRIX.dbValues;

        let matrix = null;
        if (
          matrixDbValues.length > 0 &&
          matrixDbValues[0] != "null" &&
          matrixDbValues[0] != null
        ) {
          matrix = matrixDbValues[0]
            .replace(/[\[\]]+/g, "")
            .split(",")
            .map(Number);
        }

        return {
          oEDEAttributeValue: oedeStr,
          matrixForSession: matrix,
        };
      }
    );
  }
  return allChildGeo;
};

/**
 * Gets 3D form if it already exists, otherwise returns null.
 */
export let getExisting3DForm = function (ftmRevision) {
  let deferred = AwPromiseService.instance.defer();

  let inputData = {
    primaryObjects: [ftmRevision],
    pref: {
      expItemRev: true,
      info: [
        {
          relationName: "V4B_ZA_3DSESSION_ST_Node",
          objectTypeNames: ["V4B_DIC_ZA"],
        },
      ],
    },
  };
  let propertyPolicy = {
    types: [
      {
        name: "V4B_FTMTKB_IssueRevision",
        properties: [
          {
            name: "item_id",
          },
          {
            name: "v4b_ftm_type",
          },
        ],
      },
    ],
  };
  propPolicyService.register(propertyPolicy);
  // Get Form Object from SOA
  soaSvc
    .post(
      "Core-2007-06-DataManagement",
      "expandGRMRelationsForPrimary",
      inputData
    )
    .then(
      function (response) {
        if (
          !!response.output &&
          response.output.length > 0 &&
          !!response.output[0].otherSideObjData &&
          response.output[0].otherSideObjData.length > 0 &&
          !!response.output[0].otherSideObjData[0].otherSideObjects &&
          response.output[0].otherSideObjData[0].otherSideObjects.length > 0
        )
          return deferred.resolve(
            response.output[0].otherSideObjData[0].otherSideObjects[0]
          );
        else deferred.resolve(null);
      },
      function (rejectedResponse) {
        // Form doesn't exist
        return deferred.resolve(null);
      }
    );

  return deferred.promise;
};

export let getOedeStringFromForm = function (formObject) {
  let OEDEString =
    formObject.props.v4b_ZBS_SNR.dbValues[0] +
    "  " +
    formObject.props.v4b_VERS_IND.dbValues[0] +
    " " +
    formObject.props.v4b_ZBS_DOKU_TEIL.dbValues[0] +
    " " +
    formObject.props.v4b_ZBS_DOKU_ALT.dbValues[0] +
    " " +
    formObject.props.v4b_ZBS_DOKF_ID.dbValues[0] +
    " " +
    formObject.props.v4b_ZBS_DOKT_ID.dbValues[0];

  return OEDEString;
};

/**
 * Copies forms in Prisma by calling "addRelationBetweenParentAndChilds" method in Prisma
 * @param {Object} ftmRevision - FTM Model Object
 * @param {Object} existing3DForm -Existing 3D Form.
 * @param {Data} data - JOSN data object.
 */
export let copyFormsInPrisma = function (ftmRevision, existing3DForm, data) {
  let solFTMIssueZAFormRelation = "V4B_ZA_Solution_Temp",
    refFTMIssueZAFormRelation = "V4B_ZA_Problem_Temp",
    propFTMIssueZAFormRelation = "V4B_ZA_Proposal_Temp",
    objectTypeName = "V4B_DIC_ZA";

  let propPolicy = {
    types: [
      {
        name: objectTypeName,
        properties: [
          {
            name: "object_name",
          },
          {
            name: "object_string",
          },
          {
            name: "v4b_PRISMA_TRANS_MATRIX",
          },
          {
            name: "v4b_ZBS_SNR",
          },
          {
            name: "v4b_VERS_IND",
          },
          {
            name: "v4b_ZBS_DOKU_TEIL",
          },
          {
            name: "v4b_ZBS_DOKU_ALT",
          },
          {
            name: "v4b_ZBS_DOKF_ID",
          },
          {
            name: "v4b_ZBS_DOKT_ID",
          },
        ],
      },
    ],
  };

  return VIMSoaUtils.getRelatedSideObjects(
    ftmRevision,
    [
      solFTMIssueZAFormRelation,
      refFTMIssueZAFormRelation,
      propFTMIssueZAFormRelation,
    ],
    objectTypeName,
    propPolicy
  ).then(function (allForms) {
    data.allForms = allForms;
    let oedeValues = [];
    oedeValues.push({
      oEDEAttributeValue: exports.getOedeStringFromForm(existing3DForm),
    });
    for (let geometryKey in allForms) {
      if (allForms[geometryKey]) {
        allForms[geometryKey].forEach(function (formObj) {
          let matrixDbValues = formObj.props.v4b_PRISMA_TRANS_MATRIX.dbValues;

          let matrix = null;
          if (
            matrixDbValues.length > 0 &&
            matrixDbValues[0] != null &&
            matrixDbValues[0] != ""
          ) {
            matrix = matrixDbValues[0]
              .replace(/[\[\]]+/g, "")
              .split(",")
              .map(Number);
            oedeValues.push({
              oEDEAttributeValue: exports.getOedeStringFromForm(formObj),
              matrixForSession: matrix,
            });
          } else {
            oedeValues.push({
              oEDEAttributeValue: exports.getOedeStringFromForm(formObj),
            });
          }
        });
      }
    }

    //ctx.preferences
    // POST Request to Prisma
    return $http
      .get({
        method: "POST",
        url: appCtxSvc.getCtx("preferences['V4B_VIM_PRISMA_URL'][0]"),
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        data: {
          jdbcinfo: exports.getPrismaJDBSinfo(),
          method: "addRelationBetweenParentAndChilds",
          oede: oedeValues,
        },
      })
      .then(function (prismaResponse) {
        data.prismaResponse = prismaResponse;
        return prismaResponse.data;
      });
  });
};
/**
 * This function for preparing input for create 3D Geometry Dic Assignment Task Form from prisma Response.
 * @function getZAFormCreateInput
 * @param {object} data - Response Data.
 */
export let getZAFormCreateInput = function (data) {
  console.log("called getZAformCreateInput")
  let info = [],
    deferred = AwPromiseService.instance.defer(),
    prismaInputs = data.PRISMAInput.oede[0];

  if (data.PRISMAInput.info != undefined) {
    messagingService.showInfo(data.PRISMAInput.info);
  }
  if (data.PRISMAInput.oede[0].lastError == undefined) {
    let ftmRevision = null,
      formObject3D = null,
      relationNameForSoa = "V4B_ZA_3DSESSION_ST_Node";

    if (data.ftmRevision) {
      ftmRevision = data.ftmRevision;
      relationNameForSoa = "";
    } else {
      // let modelObjs = data.createFTMResponseData.ServiceData.modelObjects;
      // for (let key in modelObjs) {
      //     if (modelObjs[key].type == "V4B_FTMTKB_IssueRevision")
      //         ftmRevision = modelObjs[key];
      // }
      let objects = data.createFTMResponseData.output[0].objects;
      for (let key in objects) {
        if (objects[key].type == "V4B_FTMTKB_IssueRevision")
          ftmRevision = objects[key];
      }
    }

    exports.getExisting3DForm(ftmRevision).then(function (existing3DForm) {
      console.log("Called getexisting3dform")
      if (!!existing3DForm && !!data.ftmRevision) formObject3D = existing3DForm;
      let ftmItemId =
        ftmRevision.props.item_id || ftmRevision.props.awp0Item_item_id;

      let prismaOEDE = data.PRISMAInput.oede;

      let map = {
        v4b_ZBS_DOKU_ALT: [prismaOEDE[0].v4b_ZBS_DOKU_ALT],
        v4b_ZBS_DOKF_ID: [prismaOEDE[0].v4b_ZBS_DOKF_ID],
        v4b_VERS_IND: [prismaOEDE[0].v4b_VERS_IND],
        v4b_ZBS_DOKT_ID: [prismaOEDE[0].v4b_ZBS_DOKT_ID],
        v4b_ZBS_DOKU_TEIL: [prismaOEDE[0].v4b_ZBS_DOKU_TEIL],
        v4b_ZBS_SNR: [prismaOEDE[0].v4b_ZBS_SNR],
        v4b_PA_ITEM_ID: [ftmItemId.dbValues[0]],
        object_name: [prismaOEDE[0].oEDEAttributeValue],
      };

      info.push({
        clientId: "CreateForm",
        formObject: formObject3D,
        parentObject: ftmRevision,
        relationName: relationNameForSoa,
        saveDB: true,
        name: prismaInputs.oEDEAttributeValue,
        formType: "V4B_DIC_ZA",
        attributesMap: map,
      });

      // add other ST forms
      for (let index = 1; index < prismaOEDE.length; index++) {
        let map2 = {
          v4b_ZBS_DOKU_ALT: [prismaOEDE[index].v4b_ZBS_DOKU_ALT],
          v4b_ZBS_DOKF_ID: [prismaOEDE[index].v4b_ZBS_DOKF_ID],
          v4b_VERS_IND: [prismaOEDE[index].v4b_VERS_IND],
          v4b_ZBS_DOKT_ID: [prismaOEDE[index].v4b_ZBS_DOKT_ID],
          v4b_ZBS_DOKU_TEIL: [prismaOEDE[index].v4b_ZBS_DOKU_TEIL],
          v4b_ZBS_SNR: [prismaOEDE[index].v4b_ZBS_SNR],
          v4b_PA_ITEM_ID: [ftmItemId.dbValues[0]],
          object_name: [prismaOEDE[index].oEDEAttributeValue],
        };

        let tcRelName = relationNameMap[prismaOEDE[index].v4b_ZA_RELATION_TYPE];

        info.push({
          clientId: "CreateForm",
          parentObject: ftmRevision,
          relationName: tcRelName,
          saveDB: true,
          name: prismaOEDE[index].oEDEAttributeValue,
          formType: "V4B_DIC_ZA",
          attributesMap: map2,
        });
      }

      let policy = {
        types: [
          {
            name: "V4B_FTMTKB_IssueRevision",
            properties: [
              {
                name: "item_id",
              },
            ],
          },
        ],
      };
      soaSvc
        .post(
          "Core-2007-01-DataManagement",
          "createOrUpdateForms",
          {
            info: info,
          },
          policy
        )
        .then(function (createOrUpdateResponse) {
          /* if (data.ftmRevision) {
                    // This means this is duplicate operation, so we need to copy all forms in Prisma
                    exports.copyFormsInPrisma(ftmRevision, existing3DForm, data).then(function (prismaCopyResponse) {
                        return deferred.resolve(createOrUpdateResponse);
                    });
                } else {
                    return deferred.resolve(createOrUpdateResponse);
                } */

          return deferred.resolve(createOrUpdateResponse);
        });
    });
    eventBus.publish("cdm.relatedModified", {
      "relatedModified": [appCtxSvc.getCtx("selected")],
      "refreshLocationFlag": true
  });
  } else {
    messagingService.showError(data.PRISMAInput.oede[0].lastError);
  }

  return deferred.promise;
};
/**
 * This function call for duplicate FTM Issue to update "v4b_ZBS_INST_ID" of forms based on PRISMA response.
 * @param {*} data
 */
export let updateFormAction = function (data) {
  let deferred = AwPromiseService.instance.defer();
  if (!!data.allForms && !!data.prismaResponse.data.oede) {
    let ftmItemID = _.get(
      data,
      "ftmRevision.props.awp0Item_item_id.dbValues[0]",
      ""
    );
    let prismaOede = data.prismaResponse.data.oede;
    let inputData = [];
    if (data.allForms.V4B_ZA_Problem_Temp) {
      let zaProblemTempForm = getFormUpdateInput(
        prismaOede,
        data.allForms.V4B_ZA_Problem_Temp,
        ftmItemID
      );
      inputData = zaProblemTempForm;
    }
    if (data.allForms.V4B_ZA_Proposal_Temp) {
      let zaProposalTempForm = getFormUpdateInput(
        prismaOede,
        data.allForms.V4B_ZA_Proposal_Temp,
        ftmItemID
      );
      if (inputData.length == 0) inputData = zaProposalTempForm;
      else inputData = inputData.concat(zaProposalTempForm);
    }
    if (data.allForms.V4B_ZA_Solution_Temp) {
      let zaSolutionTempForm = getFormUpdateInput(
        prismaOede,
        data.allForms.V4B_ZA_Solution_Temp,
        ftmItemID
      );
      if (inputData.length == 0) inputData = zaSolutionTempForm;
      else inputData = inputData.concat(zaSolutionTempForm);
    }
    if (inputData.length == 0) {
      _.defer(function () {
        return deferred.resolve();
      });
    }
    soaSvc
      .post("Core-2007-01-DataManagement", "createOrUpdateForms", {
        info: inputData,
      })
      .then(function (updateForm) {
        return deferred.resolve(updateForm);
      });
  } else {
    _.defer(function () {
      return deferred.resolve();
    });
  }

  return deferred.promise;
};
/**
 * This function is used for preparing input for update Form with instance id from prisma Response.
 * @function getFormUpdateInput
 * @param {object} data - Response Data.
 */
let getFormUpdateInput = function (oede, selectedForms, ftmItemID) {
  let info = [];

  let oedeIndex;
  for (oedeIndex = 0; oedeIndex < oede.length; oedeIndex++) {
    let prismaInputs = oede[oedeIndex];
    let v4b_ZBS_INST_ID = [prismaInputs.v4b_ZBS_INST_ID]; //get instance id from PRISMA output
    if (prismaInputs.v4b_ZBS_INST_ID != null) {
      let attributesMap = {
        v4b_ZBS_INST_ID: v4b_ZBS_INST_ID,
        v4b_PA_ITEM_ID: [ftmItemID],
      };

      for (let formIndex = 0; formIndex < selectedForms.length; formIndex++) {
        let objName = selectedForms[formIndex].props.object_name.dbValues[0];
        let isValid = validateForm(objName, prismaInputs);
        if (isValid) {
          let updateFormInput = {
            clientId: "AWC_VIM_UpdateForms",
            formObject: selectedForms[formIndex],
            saveDB: true,
            attributesMap: attributesMap,
          };
          info.push(updateFormInput);
          break;
        }
      }
    } else {
      continue;
    }
  }
  return info;
};

let validateForm = function (objName, prismaInputs) {
  let isValid = false;
  if (objName.indexOf(prismaInputs.v4b_ZBS_SNR) != -1) {
    if (objName.indexOf(prismaInputs.v4b_ZBS_DOKU_TEIL) != -1) {
      if (objName.indexOf(prismaInputs.v4b_ZBS_DOKU_ALT) != -1) {
        if (objName.indexOf(prismaInputs.v4b_ZBS_DOKT_ID) != -1) {
          if (objName.indexOf(prismaInputs.v4b_ZBS_DOKF_ID) != -1) {
            if (objName.indexOf(prismaInputs.v4b_VERS_IND) != -1) {
              isValid = true;
            }
          }
        }
      }
    }
  }
  return isValid;
};

//This function is use to update Trans Matrix of selected FTM Issue.
export let updateTransMatrixOfVIMIssue = function (
  updatedAnchor,
  updatedLblOff
) {
  let relationContext = appCtxSvc.getCtx("relationContext");
  if (
	relationContext?.relationInfo?.length > 0 &&
    relationContext.relationInfo[0]?.secondaryObject?.type === "V4B_FTMTKB_IssueRevision"
  ) {
    let inputData = {
      info: [
        {
          object: relationContext.relationInfo[0].secondaryObject,
          vecNameVal: [
            {
              name: "v4b_IssueTransMatrix_Anchor",
              values: [updatedAnchor],
            },
            {
              name: "v4b_IssueTransMatrix_LblOff",
              values: [updatedLblOff],
            },
          ],
        },
      ],
    };
    soaSvc
      .post("Core-2010-09-DataManagement", "setProperties", inputData)
      .then(function (response) {
        eventBus.publish("cdm.relatedModified", {
          "relatedModified": [appCtxSvc.ctx.xrtSummaryContextObject],
          "refreshLocationFlag": true
      });
      eventBus.publish("VisualizationVIMContainer.contentLoaded");
        return response;
      })
      .catch(function (error) {
        let localTextBundle = localeService.getLoadedText(
          app.getBaseUrlPath() + "/i18n/VIMToolInfoCommandsMessages"
        );
        messagingService.showError(
          localTextBundle["ErrorMessageIssueNotModifiable"]
        );
      });
  }
};
export let getSecondaryData = function (selectedParts) {
  let secondaryData = [];
  let uniqueParts = Array.from(
    new Set(
      selectedParts.map((part) => part.props.awb0UnderlyingObject.dbValues[0])
    )
  );
  uniqueParts.forEach(function (unqPart) {
    let sData = {
      secondary: {
        type: "T4B_DesignItemRevision",
        uid: unqPart,
      },
    };
    secondaryData.push(sData);
  });
  return secondaryData;
};

/**
 * @function getOEDEValuefromPref - Read preference for getting OEDE attribute value for selected object type
 * @param {Object} data - Declarative viewmodel Data object
 */
let getOEDEValuefromPref = function (data) {
  let listofPrefvalue = appCtxSvc.getCtx('preferences.V4B_VIM_ABS_ROOT_OEDE');
  let parentOEDEObject = null;
  let selectedObject = null;
  if (data.ftmRevision != undefined || data.createFTMResponseData != undefined) {
      selectedObject = "V4B_FTMTKB_IssueRevision"
  } 
  if (listofPrefvalue) {
      listofPrefvalue.forEach(function (eachObjectType) {
          let splitofOEDEandObject = eachObjectType.split(':');
          if (selectedObject === splitofOEDEandObject[0]) {
              parentOEDEObject = {
                  "oEDEAttributeValue": splitofOEDEandObject[1]
              };
          }
      });
  }
  return parentOEDEObject;
};

/**
 * @function getChildOEDEValue - getting OEDE attribute from newly created object
 * @param {Object} data - Declarative viewmodel Data object
 */
let getChildOEDEValue = function (data) {
  let oedeValue = null;
  let childOEDEObject = null;
  if (data.PRISMAInput != undefined) {
      oedeValue = data.PRISMAInput.oede[0].oEDEAttributeValue;
      childOEDEObject = {
          "oEDEAttributeValue": oedeValue
      };
  } else if (data.prismaDiCStructOutput != undefined) {
      oedeValue = data.prismaDiCStructOutput.oede[0].oEDEAttributeValue;
      childOEDEObject = {
          "oEDEAttributeValue": oedeValue
      };
  }
  return childOEDEObject;
};

/**
 * @function callPrismaToCreateRelation - Calls Prisma to Add relation
 * @param {Object} data - Declarative viewmodel Data object
 */
export let callPrismaToCreateRelation = function (data) {
  let parentOEDE = getOEDEValuefromPref(data);
  let childOEDE = getChildOEDEValue(data);
  // POST Request to Prisma
  return $http({
      method: 'POST',
      url: appCtxSvc.getCtx('preferences.V4B_VIM_PRISMA_URL')[0],
      headers: {
          'Content-Type': "application/json; charset=utf-8"
      },
      data: {
          "method": "addRelationBetweenParentAndChilds",
          "jdbcinfo": getPrismaJDBSinfo(),
          "oede": [parentOEDE, childOEDE]
      }
  }).then(function (prismaRelationResponse) {
      return prismaRelationResponse.data;
  });
};

export let checkSelectedPartIsAlreadyAttached = function (data) {
  var deferred = AwPromiseService.instance.defer();
  data.isPartAlreadyAttached = false;
  var inputData = {
      "primaryObjects": [appCtxSvc.ctx.xrtSummaryContextObject],
      "pref": {
          "expItemRev": true,
          "info": [{
              "relationName": "CMHasProblemItem",
              "objectTypeNames": ["T4B_DesignItemRevision"]
          }]
      }
  };
  return soaSvc.post("Core-2007-06-DataManagement", "expandGRMRelationsForPrimary", inputData).then(function (response) {
      data.isPartAlreadyAttached = false;
      if (!!response.output &&
          response.output.length > 0 &&
          !!response.output[0].otherSideObjData &&
          response.output[0].otherSideObjData.length > 0 &&
          !!response.output[0].otherSideObjData[0].otherSideObjects &&
          response.output[0].otherSideObjData[0].otherSideObjects.length > 0)
          {
              for (var jj = 0; jj < response.output[0].otherSideObjData[0].otherSideObjects.length; jj++) {
                  
                  if(data.dataProviders.filteredDesignItemDataProvider.selectedObjects[0].uid == response.output[0].otherSideObjData[0].otherSideObjects[jj].uid)
                  {
                      data.isPartAlreadyAttached = true; 
                      break;
                  }
               }
          }

          if(data.isPartAlreadyAttached == false)
          {
              if(appCtxSvc.ctx.NONE && appCtxSvc.ctx['NONE'].editInProgress())
              {
                eventBus.publish("V4B_events.registerVIMCtxForAddPart");
              }
              else 
              {
                eventBus.publish("V4B_events.attachSelectedDesignPartItem");
              }
              
          }
          else
          {
            messagingService.showInfo(data.i18n.designPartAlreadyAttachedToFTM);
          }
      return deferred.resolve(response);
  }, function (errObj) {
      var msg = errObj;
      errObj && errObj.message && (msg = errObj.message);
      messagingService.showError(msg);
  });
  deferred.promise;
};
export let showStandInReleaseInfoMsgAction = function (data) {
  let listOfFTMIssueSuccessful;
  let listOfFTMIssueFailed;

  var localTextBundle = localeService.getLoadedText(app.getBaseUrlPath() + "/i18n/BMWCommonOneStepCommandMessages");
  var msgStandInSuccessful = localTextBundle['PerformStandInSuccessful']
  var msgReleaseSuccessful = localTextBundle['PerformReleaseSuccessful']
  var msgStandInFailed = localTextBundle['PerformStandInFailed']
  var msgReleaseFailed = localTextBundle['PerformReleaseFailed']

  if(data.listOfSuccessfulVIMIssues != undefined && data.listOfSuccessfulVIMIssues.length > 0)
  {
      listOfFTMIssueSuccessful = data.listOfSuccessfulVIMIssues.join(",");
      if(data.isStandIn)
      {
          msgStandInSuccessful = msgStandInSuccessful + "\n"+ listOfFTMIssueSuccessful;
          messagingService.showInfo(msgStandInSuccessful);
      }
      else
      {
          msgReleaseSuccessful = msgReleaseSuccessful + "\n" + listOfFTMIssueSuccessful;
          messagingService.showInfo(msgReleaseSuccessful);
      }
  }
  if(data.listOfFailedVIMIssues != undefined && data.listOfFailedVIMIssues.length > 0 )
  {      
      listOfFTMIssueFailed = data.listOfFailedVIMIssues.join(",");
      if(data.isStandIn)
      {
          msgStandInFailed = msgStandInFailed + "\n"+ listOfFTMIssueFailed;
          messagingService.showInfo(msgStandInFailed);
      }
      else
      {
          msgReleaseFailed = msgReleaseFailed + "\n"+ listOfFTMIssueFailed;
          messagingService.showInfo(msgReleaseFailed);
      }

  }
  appCtxSvc.registerCtx("vimCtx.isStandIn", data.isStandIn);
  eventBus.publish("cdm.relatedModified", {
      "relatedModified": [appCtxSvc.getCtx("selected")],
      "refreshLocationFlag": true
  });

};

export let refreshPage = function () {

  eventBus.publish("cdm.relatedModified", {
    "relatedModified": [appCtxSvc.getCtx("selected")],
    "refreshLocationFlag": true
});
}
exports = {
  refreshPage,
  startWorkflow,
  createRelateAndSubmitAction,
  createRelateAndSubmitResponse,
  getPrismaJDBSinfo,
  getOEDEForCreate3DZA,
  getExisting3DForm,
  getOedeStringFromForm,
  copyFormsInPrisma,
  getZAFormCreateInput,
  updateFormAction,
  updateTransMatrixOfVIMIssue,
  getSecondaryData,
  callPrismaToCreateRelation,
  checkSelectedPartIsAlreadyAttached,
  showStandInReleaseInfoMsgAction
};

export default exports;
