/* eslint-disable no-console */
// @<COPYRIGHT>@
// ==================================================
// Copyright 2017.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/**
 * This service will do the following
 * 1. Inflate occurrrence properties for cpd0design_element_id and cpd0source_object
 * 2. Inflate cpd0source_object(T4B_DesignItemRevision) for t4b_comp_DOKU_ID
 * 3. Create a Map with key as cpd0design_element_id and value as t4b_comp_DOKU_ID
 *
 * @module js/g4b_PDMVisService
 *
 */

import app from "app";
import $ from "jquery";
import eventBus from "js/eventBus";
import soaSvc from "soa/kernel/soaService";
import appCtxService from "js/appCtxService";
import cdm from "soa/kernel/clientDataModel";
import g4b_toggleVaaSAnnotationsService from "js/g4b_toggleVaaSAnnotationsService";
import AwPromiseService from "js/awPromiseService";
import dataManagementSvc from "soa/dataManagementService";
import propPolicySvc from "soa/kernel/propertyPolicyService";
import cdmService from 'soa/kernel/clientDataModel';
import _ from 'lodash';

let exports = {};

let vaasPopupWindow = null;
let vaasResetRequired = false;
let array_DesignElementsToVaaS = [];
let savedRecipe = {};
let handlingAWSelectionEvent = false;
let selectionEventTimeout = null;

//Enum used to determine whether nodes should be shown only (starting a viewer session) or toggled when processing the response of getOccurrences7
let VAAS_ACTION = {
  showOnly: 1,
  toggle: 2,
};

/**
 * Author Dhiraj
 *
 */

//Hide and Show in VaaS
let localPartitionCache = [];
// eslint-disable-next-line no-unused-vars
let partition = {
  partition: localPartitionCache,
};
// Vaas In Visulise
let sessionCacheForVaasVisulalisation = [];
// eslint-disable-next-line no-unused-vars
let vaasObject = {
  vaasInVisulationList: sessionCacheForVaasVisulalisation,
};
//*****************************************************************/

function checkVaaSAvailable(deferred, counter) {
  if (counter > 10) {
    deferred.reject();
  }
  //check if webvis is actually ready to handle requests
  if (vaasPopupWindow.webvis != null && vaasPopupWindow.webvis != undefined) {
    deferred.resolve();
  } else {
    counter++;
    setTimeout(function () {
      checkVaaSAvailable(deferred, counter);
    }, 1000);
  }
}

function openVaaSWindow() {
  let deferred = AwPromiseService.instance.defer();

  if (vaasPopupWindow == null || vaasPopupWindow.closed == true) {
    let vaasFrameAddress = null;
    //Prod environment: https://tc4jbi.bmwgroup.net/awc_gp_01 or https://tc4jb.bmwgroup.net/awc
    //New Prod environment: https://tegggi.bmwgroup.net// (03.07.2024)
    if (window.location.host.indexOf("tc4jb.bmwgroup.net") !== -1 || window.location.host.indexOf("tegggi.bmwgroup.net") !== -1) {
      vaasFrameAddress =
        app.getBaseUrlPath() + "/html/g4b_webVis/g4b_webvis_prod.html";
    }
    //Integ environment: https://tc4jbi.bmwgroup.net/
    //New Integ environment https://tegggi-int.bmwgroup.net/ (03.07.2024)
    else if (window.location.host.indexOf("tc4jbi.bmwgroup.net") !== -1 || window.location.host.indexOf("tegggi-int.bmwgroup.net") !== -1) {
      vaasFrameAddress =
        app.getBaseUrlPath() + "/html/g4b_webVis/g4b_webvis_int.html";
    }
    //Test environments: e.g. https://ltawsteg300.gi-dev.eu-central-1.aws.cloud.bmw:3000/
    else if (window.location.host.indexOf("aws.cloud.bmw") !== -1) {
      vaasFrameAddress =
        app.getBaseUrlPath() + "/html/g4b_webVis/g4b_webvis_int.html";
    }
    //Siemens Dev environment
    else {
      vaasFrameAddress =
        app.getBaseUrlPath() + "/html/g4b_webVis/g4b_webvis_debug.html";
    }

    let params =
      "scrollbars=nostatus=no,location=no,toolbar=no,menubar=no,width=1280,height=900,left=100,top=32";
    vaasPopupWindow = window.open(vaasFrameAddress, "_blank", params);

    vaasPopupWindow.addEventListener("beforeunload", function () {
      if (appCtxService.getCtx("isIframeVisible") == undefined) {
        appCtxService.registerCtx("isIframeVisible", false);
      } else {
        appCtxService.updateCtx("isIframeVisible", false);
      }
    });

    window.callParentJS = function (nodeArray) {
      let webvisSelectedNodes = vaasPopupWindow.webvis.getSelectedNodes();
      //appCtxService.ctx.occmgmtContext.pwaSelectionModel.selectNone();
      triggerSelection(webvisSelectedNodes);
    };
    //set language of AW session in a variable
    window.awLang = appCtxService
      .getCtx("userSession")
      .props.fnd0locale.dbValue.substring(0, 2);

    window.vaasPopupWindow = vaasPopupWindow;

    if (appCtxService.getCtx("isIframeVisible") == undefined) {
      appCtxService.registerCtx("isIframeVisible", false);
    } else {
      appCtxService.updateCtx("isIframeVisible", true);
    }
  }
  let counter = 0;
  setTimeout(function () {
    checkVaaSAvailable(deferred, counter);
  }, 1000);

  return deferred.promise;
}

// eslint-disable-next-line require-jsdoc
function triggerSelection(nodeArray) {
  let selectedUidList = [];
  if (handlingAWSelectionEvent == false) {
    handlingAWSelectionEvent = true;
  }

  for (let i = 0; i < nodeArray.length; i++) {
    let makeSelectionPromise = makeSelection(nodeArray[i]);
    $.when(makeSelectionPromise).done(function (uid) {
      selectedUidList.push(uid);
      if (selectedUidList.length == nodeArray.length) {
        console.log("performMultiselect on selectedUidList: ", selectedUidList);
        appCtxService.getCtx("occmgmtContext.treeDataProvider.selectionModel").selectNone();
        performMultiSelect(selectedUidList);
      }
    });
  }
}

export let getParentUid = function( modelObject ) {
  if( modelObject && modelObject.props ) {
      var props = modelObject.props;

      var uid;

      if( props.awb0BreadcrumbAncestor && !_.isEmpty( props.awb0BreadcrumbAncestor.dbValues ) ) {
          uid = props.awb0BreadcrumbAncestor.dbValues[ 0 ];
      } else if( props.awb0Parent && !_.isEmpty( props.awb0Parent.dbValues ) ) {
          uid = props.awb0Parent.dbValues[ 0 ];
      }

      if( cdmService.isValidObjectUid( uid ) ) {
          return uid;
      }
  }

  return null;
};


let performMultiSelect = function (selectedUidList) {
  dataManagementSvc.loadObjects(selectedUidList).then(function () {
    appCtxService.ctx.occmgmtContext.currentState.c_uid = selectedUidList[0];
    let selObj = cdm.getObject( selectedUidList[0] ); 
    if( selObj ) {
      let parentObj = getParentUid( selObj );
      appCtxService.ctx.occmgmtContext.currentState.o_uid = parentObj;
    }
    /* Multiselect using the setSelection(PUUidArray)  function does not work correctly OOTB. All PUs get selected but
     *  the tree is not expanded to show all PU selections. In order to show all PUs as expanded and selected
     *  nodes, we need to do setSelection(PUUid) for the 1st node and then perform addToSelection(PUUid)
     *  one by one on the remaining PUUids.
     */
    let selectionModel = appCtxService.getCtx("occmgmtContext.treeDataProvider.selectionModel");
    if(selectionModel.getDpListener) {
      let occDataProvider = selectionModel.getDpListener();
      if(occDataProvider) {
        occDataProvider.selectionModel.setMultiSelectionEnabled(true);
        occDataProvider.selectionModel.setSelection(selectedUidList[0]);
      }
    }
    let remainingUidsToSelect = selectedUidList.slice(1,selectedUidList.length);
    /* We are subscribing to the 'appCtx.update' event and whenever it contains the eventData contains
     * name 'visibleServerCommands',it signifies that the selection is changed on the UI
     */
    let selectionMadeSubscription = eventBus.subscribe(
      "primaryWorkArea.selectionChangeEvent",
      function (eventData) {
        if(eventData?.selectedObjects.length > 0) {
          eventBus.unsubscribe(selectionMadeSubscription);

          let multiDeferred = AwPromiseService.instance.defer();
          let deferredsArray = [];
          remainingUidsToSelect.forEach(function (remainingUid) {
            deferredsArray.push(nodeSelectedPromise(remainingUid));
          });

          $.when.apply(null, deferredsArray).done(function () {
            console.log(
              "Done All! Remaining IDs to select:",
              remainingUidsToSelect
            );
            if (handlingAWSelectionEvent) {
              handlingAWSelectionEvent = false;
            }
          });
        }    
      } 
    );
  });
};

let nodeSelectedPromise = function (puUid) {
  let deferred = AwPromiseService.instance.defer();
  appCtxService.ctx.occmgmtContext.currentState.c_uid = puUid;
  let selObj = cdm.getObject( puUid ); 
  if( selObj ) {
    let parentObj = getParentUid( selObj );
    appCtxService.ctx.occmgmtContext.currentState.o_uid = parentObj;
  }
  let selectionModel = appCtxService.getCtx("occmgmtContext.treeDataProvider.selectionModel");
  if(selectionModel.getDpListener) {
    let occDataProvider = selectionModel.getDpListener();
    if(occDataProvider) {
      occDataProvider.selectionModel.addToSelection(puUid);
    }
  }
  let selectionMadeSubscription = eventBus.subscribe(
    "primaryWorkArea.selectionChangeEvent",
    function (eventData) {
      if(eventData?.selectedObjects.length > 0) {
        eventBus.unsubscribe(selectionMadeSubscription);
        deferred.resolve();
      }
    }
  );
  return deferred.promise;
};

let makeSelection = function (nodeID) {
  let functiondeferred = AwPromiseService.instance.defer();

  let vaasInVisulationList = JSON.parse(
    sessionStorage.getItem("vaasInVisulationList")
  );
  let uid = null;
  let occMgmtContext = appCtxService.getCtx("occmgmtContext");
  let isPartitionSchemeApplied = true;
  let partitionSchemeUID = "";
  let productContextInfoObject = null;

  for (let i = 0; i < vaasInVisulationList.length; i++) {
    if (vaasInVisulationList[i].value == nodeID) {
      uid = vaasInVisulationList[i].uid;
      break;
    }
  }

  let deferred = AwPromiseService.instance.defer();

  //If the node is a subordinate DE, there will be nothing in the AW tree to select
  if (uid.indexOf("subordinateDE") != -1) {
    return;
  }

  //check if uid is available in client data model
  if (cdm.getObject(uid) == null) {
    let inputData = {
      uids: [uid],
    };
    soaSvc
      .post("Core-2007-09-DataManagement", "loadObjects", inputData)
      .then(function () {
        deferred.resolve();
      });
  }
  //ELSE: Selected Object is available in the client data model
  else {
    deferred.resolve();
  }

  $.when(deferred.promise).done(function () {
    //Worksets can have multiple subsets. Each subset does have it's own product context and it is on the ctx.occMgmtContext.elementToPCIMap
    if (
      occMgmtContext.elementToPCIMap != null &&
      Object.keys(occMgmtContext.elementToPCIMap).length > 0
    ) {
      //get part of the PartUsage UID string that identifies the subset containing it
      let subsetIdentifyingUID = uid.substring(
        uid.indexOf("/", uid.indexOf("PR:SR::N::")) + 1,
        uid.indexOf("/", uid.indexOf("/", uid.indexOf("PR:SR::N::")) + 1)
      );

      for (let key in occMgmtContext.elementToPCIMap) {
        //iterate through the map of product context infos and pick the one with the key that matches the subset which contains our PartUsage
        if (key.indexOf(subsetIdentifyingUID) != -1) {
          //if the product context UID contains a substring "PS:" it has a partition scheme.
          //if not, it is a flat list
          if (occMgmtContext.elementToPCIMap[key].indexOf(",PS:") != -1) {
            isPartitionSchemeApplied = true;
            partitionSchemeUID = occMgmtContext.elementToPCIMap[key].substring(
              occMgmtContext.elementToPCIMap[key].indexOf(",PS:") + 4,
              occMgmtContext.elementToPCIMap[key].indexOf(
                ",",
                occMgmtContext.elementToPCIMap[key].indexOf(",PS:") + 1
              )
            );
          } else {
            isPartitionSchemeApplied = false;
          }
          //get the product context info object from the CDM for later use in the method 'computeStableIDChain'
          productContextInfoObject = cdm.getObject(
            occMgmtContext.elementToPCIMap[key]
          );
          break;
        }
      }
    } else {
      if (occMgmtContext.productContextInfo.uid.indexOf(",PS:") != -1) {
        isPartitionSchemeApplied = true;
        partitionSchemeUID = occMgmtContext.productContextInfo.uid.substring(
          occMgmtContext.productContextInfo.uid.indexOf(",PS:") + 4,
          occMgmtContext.productContextInfo.uid.indexOf(
            ",",
            occMgmtContext.productContextInfo.uid.indexOf(",PS:") + 1
          )
        );
      } else {
        isPartitionSchemeApplied = false;
      }
      productContextInfoObject = occMgmtContext.productContextInfo;
    }

    if (isPartitionSchemeApplied) {
      //get stableId Chain
      let deferred = computeStableIdChain(
        uid,
        partitionSchemeUID,
        productContextInfoObject
      );

      $.when(deferred).done(function (stableIdChain) {
        let uid_start = uid.substring(0, uid.indexOf(",,AWB4GB")); //include everything until the last '/' in the PR section of the uid string
        let uid_end = uid.substring(uid.indexOf(",,AWB4GB")); // the remainder of the string

        if (uid_start.indexOf(stableIdChain) == -1) {
          console.log("Stable ID Chain computed: " + stableIdChain);
          uid = uid_start + stableIdChain + "/" + uid_end;
        } else {
          console.log(
            "Stable ID Chain computed was already part of UID. Skipping construction of new UID string."
          );
          uid = uid_start + uid_end;
        }
        if (uid.indexOf("//") > -1) {
          uid = uid.replace("//", "/");
        }

        console.log("new UID: " + uid);

        let inputData = {
          uids: [uid],
        };
        soaSvc
          .post("Core-2007-09-DataManagement", "loadObjects", inputData)
          .then(function () {
            cdm.getObject(uid);
            // appCtxService.ctx.occmgmtContext.pwaSelectionModel.setSelection(uid);
            //The line below is no longer needed as of AW 4.0.4.1.1, keeping it in code for reference if selection behaviour should change unexpectedly in future
            // angular.element('aw-sublocation-body').scope().$evalAsync(); // for triggering angular digest cycle..(for quick refresh of aw-sublocation-body)
            console.log("Node with ID " + nodeID + " is selected");
            functiondeferred.resolve(uid);
          });
      });
    } else {
      // appCtxService.ctx.occmgmtContext.pwaSelectionModel.setSelection(uid);
      //The line below is no longer needed as of AW 4.0.4.1.1, keeping it in code for reference if selection behaviour should change unexpectedly in future
      // angular.element('aw-sublocation-body').scope().$evalAsync(); // for triggering angular digest cycle..(for quick refresh of aw-sublocation-body)
      console.log("Node with ID " + nodeID + " is selected");
      functiondeferred.resolve(uid);
    }

    //Send annotation data to VaaS if the switch is enabled
    if (appCtxService.getCtx("vaasAnnotationsEnabled") == true) {
      let partUsageObj = cdm.getObject(
        cdm.getObject(uid).props.awb0UnderlyingObject.dbValues[0]
      );
      let fgb0DesignComponent = cdm.getObject(
        cdm.getObject(uid).props.fgb0DesignComponent.dbValues[0]
      );
      let inputDataVaasAnnotations = {};
      inputDataVaasAnnotations.attributes = ["g4b_VARIANT_FORMULA"];
      inputDataVaasAnnotations.objects = [partUsageObj];
      //let j = i;
      soaSvc
        .post(
          "Core-2006-03-DataManagement",
          "getProperties",
          inputDataVaasAnnotations
        )
        .then(function () {
          if (
            partUsageObj.props.g4b_VARIANT_FORMULA != null &&
            partUsageObj.props.g4b_VARIANT_FORMULA != ""
          ) {
            fgb0DesignComponent.props.g4b_PV_VARIANT_FORMULA =
              partUsageObj.props.g4b_VARIANT_FORMULA;
          }
          g4b_toggleVaaSAnnotationsService.createAnnotation(
            nodeID,
            fgb0DesignComponent
          );
        });
    }
  });
  return functiondeferred.promise;
};

/**
 * This function returns a stable ID Chain for a part usage, from the leaf element up to the opened Product itself.
 * The function uses the property "cpd0WherePartitioned" on the PartUsage itself to find the first parent partition.
 * Afterwards the function operation "whereUsed" from Data Management service is used to compute the remaining steps in
 * the hierarchy.
 *
 * @param {*} partUsageUiD The UID of the Fgb0PartUsage to compute the stableID chain for
 * @param {*} partitionSchemeUID The UID of the partition Scheme that is applied to the subset / design
 *
 * @returns returns a promise that will be resolved when the stableID chain is computed
 */
function computeStableIdChain(
  partUsageUid,
  partitionSchemeUID,
  productContextInfoObject
) {
  let deferred = AwPromiseService.instance.defer();
  let deferred2;

  let fgb0partUsageObj = cdm.getObject(partUsageUid);
  if (fgb0partUsageObj == null || fgb0partUsageObj == undefined) {
    deferred.reject();
    return deferred.promise;
  }

  let g4bPartUsageObj = null;
  if (fgb0partUsageObj.props.awb0UnderlyingObject != null) {
    g4bPartUsageObj = cdm.getObject(
      fgb0partUsageObj.props.awb0UnderlyingObject.dbValues[0]
    );
  }

  //get partition scheme object
  let partitionScheme = cdm.getObject(partitionSchemeUID);

  //Get parent partition of the Part Usage
  //Prepare inputData for wherePartitioned2 SOA call
  let occMgmtContext = appCtxService.getCtx("occmgmtContext");

  let configurationContext = {
    uid: productContextInfoObject.props.fgf0ModelConfigContext.dbValues[0],
    type: cdm.getObject(
      productContextInfoObject.props.fgf0ModelConfigContext.dbValues[0]
    ).type,
  };

  let modelContext = "";
  if (occMgmtContext.rootElement.type == "Fgf0ApplicationModel") {
    modelContext = {
      uid: occMgmtContext.rootElement.props.awb0UnderlyingObject.dbValues[0],
      type: occMgmtContext.rootElement.props.awb0UnderlyingObjectType
        .dbValues[0],
    };
  } else if (occMgmtContext.rootElement.type == "Fgd0DesignSubsetElement") {
    modelContext = {
      uid: occMgmtContext.rootElement.props.fgd0ModelObject.dbValues[0],
      type: cdm.getObject(
        occMgmtContext.rootElement.props.fgd0ModelObject.dbValues[0]
      ).type,
    };
  } else if (occMgmtContext.rootElement.type == "Awb0DesignElement") {
    let subsetObj = cdm.getObject(
      productContextInfoObject.props.awb0Product.dbValues[0]
    );
    modelContext = {
      uid: subsetObj.props.cpd0source_object.dbValues[0],
      type: cdm.getObject(subsetObj.props.cpd0source_object.dbValues[0]).type,
    };
  }

  let inputData = {
    inputs: [
      {
        configurationContext: configurationContext,
        modelContext: modelContext,
        partitionScheme: {uid: partitionScheme.uid, type: partitionScheme.type},
        partitionable: {uid: g4bPartUsageObj.uid, type: g4bPartUsageObj.type},
      },
    ],
  };

  soaSvc
    .postUnchecked(
      "Partition-2018-06-PartitionManagement",
      "wherePartitioned2",
      inputData
    )
    .then(function (response) {
      deferred2 = getParentsChain(
        response.output[0].memberInfo[0].partition.uid,
        configurationContext
      );

      $.when(deferred2).done(function (stableIdChain) {
        deferred.resolve(
          stableIdChain + response.output[0].memberInfo[0].partition.uid + "/"
        ); //add the outermost leaf partition UID at the end
      });
    });

  return deferred.promise;
}

function getParentsChain(inputObjUid, productContextInfoObject) {
  let deferred = AwPromiseService.instance.defer();
  let stableIdChain = "";

  let inputData = {
    inputs: [
      {
        configurationContext: productContextInfoObject,
        childPartitions: [
          {
            uid: inputObjUid,
            type: cdm.getObject(inputObjUid).type,
          },
        ],
        maxLevelCount: 0,
        maxParentCount: 0,
        clientId: "AWC",
      },
    ],
  };

  soaSvc
    .post("Partition-2018-06-PartitionManagement", "getParents", inputData)
    .then(function (response) {
      /**
       *  getParents returns two arrays in the response:
       *  response.output[0].childParentMap[0] contains all the children
       *  response.output[0].childParentMap[1] contains all the respective parents
       *
       *  Algorithm for calculating the stable ID chain below works as follows:
       *
       *  - Look for the UID of the leaf level partition within the children map
       *  - Take the index of the position where the child was found
       *  - Get the parent for this particular child from the parent map at the same index
       *  - [If the index on the parent map is an empty array, then we are at the top and done]
       *  - Push the UID of the parent partition on the stable ID chain array
       *  - The parent becomes the new child and the process repeats (until there is no more parent)
       *
       *  At the end, the array is reversed and the elements concatenated into the stable ID chain string
       */

      let childrenMap = response.output[0].childParentMap[0];
      let parentsMap = response.output[0].childParentMap[1];

      let index = 0;
      let currentChild = inputObjUid;
      let topReached = false;
      let stableIDChain_arr = [];

      while (!topReached) {
        for (let k = 0; k < childrenMap.length; k++) {
          if (childrenMap[k].uid == currentChild) {
            index = k;
            break;
          }
        }
        if (parentsMap[index].length > 0) {
          stableIDChain_arr.push(parentsMap[index][0].uid);
          currentChild = parentsMap[index][0].uid;
        } else {
          topReached = true;
        }
      }

      stableIDChain_arr.reverse();
      for (let k = 0; k < stableIDChain_arr.length; k++) {
        stableIdChain += stableIDChain_arr[k];
        stableIdChain += "/";
      }

      deferred.resolve(stableIdChain);
    });

  return deferred.promise;
}

/**
 * This function checks the given json visualisation cache for the existance of a given PartUsage / DesignElement uid
 * @param {*} JSON_visCache
 * @param {*} param_uid
 */
function existsDEinVaaSContext(
  JSON_visCache,
  param_dokuid,
  param_transformMatrix
) {
  let nodeInformation = undefined;

  for (let i = 0; i < JSON_visCache.length; i++) {
    if (
      JSON_visCache[i].dokuId == param_dokuid &&
      JSON_visCache[i].transformMatrix == param_transformMatrix
    ) {
      nodeInformation = JSON_visCache[i];
      break;
    }
  }

  return nodeInformation;
}

function getNodeIDbyDokuIDandTransformMatrix(
  param_dokuId,
  param_transformationMatrix
) {
  let nodeId = undefined;

  let vaasVisulalisationList = JSON.parse(
    sessionStorage.getItem("vaasInVisulationList")
  );
  for (let i = 0; i < vaasVisulalisationList.length; i++) {
    if (
      vaasVisulalisationList[i].dokuId == param_dokuId &&
      vaasVisulalisationList[i].transformMatrix == param_transformationMatrix
    ) {
      nodeId = vaasVisulalisationList[i].value;
      break;
    }
  }

  return nodeId;
}

function updateJsonVisCacheElement(
  param_JSONVisCache,
  param_nodeId,
  param_nodeInformation
) {
  for (let i = 0; i < param_JSONVisCache.length; i++) {
    if (param_JSONVisCache[i].nodeId == param_nodeId) {
      param_JSONVisCache[i].transformMatrix =
        param_nodeInformation.transformMatrix;
      param_JSONVisCache[i].uid = param_nodeInformation.uid;
      param_JSONVisCache[i].value = param_nodeInformation.value;
      param_JSONVisCache[i].visibility = param_nodeInformation.visibility;
    }
  }
}

function resetVaaSScene() {
  //Clearing whole sessionStorage data which will remove other important data so commenting it.
  //sessionStorage.clear();
  let deferred = AwPromiseService.instance.defer();

  if (vaasPopupWindow != null && vaasPopupWindow.closed == false) {
    // vaasPopupWindow.location.reload(true);
    if (vaasPopupWindow.webvis != undefined) {
      vaasPopupWindow.webvis.remove();
    }
  } else if (vaasPopupWindow.closed == true) {
    openVaaSWindow();
  }
  if (
    array_DesignElementsToVaaS != null &&
    array_DesignElementsToVaaS.length > 0
  ) {
    array_DesignElementsToVaaS = null;
    g4b_toggleVaaSAnnotationsService.clearAnnotationCache();
  }
  vaasResetRequired = false;

  let counter = 0;
  setTimeout(function () {
    checkVaaSAvailable(deferred, counter);
  }, 1000);
  return deferred.promise;
}

/*
    Function for clearing filter on a partition
*/
function removeFilterFromPartition(ctx) {
  let deferred = AwPromiseService.instance.defer();
  let inputData = {
    inputData: {
      product: {
        uid: ctx.pselected.props.awb0UnderlyingObject.dbValues[0],
      },
      parentElement: ctx.mselected[0].props.awb0Parent.dbValues[0],
      filter: {
        searchFilterCategories: [],
        searchFilterMap: {},
        fetchUpdatedFilters: false,
        recipe: [
          {
            criteriaType: "Partition",
            criteriaOperatorType: "Clear",
            subCriteria: [],
          },
        ],
        searchFilterFieldSortType: "Priority",
        searchSortCriteria: [],
      },
    },
  };

  soaSvc
    .post(
      "Internal-ActiveWorkspaceBom-2022-06-OccurrenceManagement",
      "getOccurrences4",
      inputData
    )
    .then(function () {
      deferred.resolve("reset completed.");
    });

  return deferred.promise;
}

/*
			Reapplyling filter after visualize in vass is complete
            Use case for this function: 
            Step 1. User applies filter(s) on partition
            Step 2. Then selects partition scheme
            Step 3. Starts visualize in vass with a partition
        */

function reapplyFilterAfterVis(ctx, selectedObject) {
  let deferred = AwPromiseService.instance.defer();

  if (savedRecipe[selectedObject.uid].partitionSchemeUid === null) {
    // When 'none' scheme is selected then filters are retained, so nothing to do.
    deferred.resolve("Done ");
  } else {
    //let filterType = savedRecipe[selectedObject.uid][0].recipe[0].criteriaType;

    //let searchFilterCategoriesArray = savedRecipe[selectedObject.uid][0].searchFilterCategories;
    //let searchFilterMapArray = savedRecipe[selectedObject.uid][0].searchFilterMap;

    //Both Atrribute and Partition type filters can be handled in the same loop

    let inputData = {
      inputData: {
        config: {
          effectivityDate: "0001-01-01T00:00:00",
          unitNo: -1,
          productContext: {
            uid: ctx.occmgmtContext.productContextInfo.uid,
          },
        },
        /*focusOccurrenceInput: {
                    element: {
                        uid: ctx.selected.uid,
                        type: ctx.selected.type
                    },
                },*/
        filter: {
          searchFilterCategories: [],
          searchFilterMap: {},
          fetchUpdatedFilters: false,
          recipe: [],
          searchFilterFieldSortType: "Priority",
          searchSortCriteria: [],
        },
        requestPref: {
          calculateFilters: ["false"],
          displayMode: ["Tree"],
          filterCriteriaOperatorType: ["Include"],
          includePath: ["true"],
          loadTreeHierarchyThreshold: ["50"],
          populateFilters: ["true"],
          savedSessionMode: ["restore"],
          startFreshNavigation: ["false"],
          useGlobalRevRule: ["false"],
          viewType: ["BOM"],
          unsetPartitionScheme: ["true"],
        },
        product: {
          uid: ctx.occmgmtContext.topElement.props.awb0UnderlyingObject
            .dbValues[0],
        },
        //parentElement: ctx.selected.props.awb0Parent.dbValues[0]
        parentElement: savedRecipe[selectedObject.uid].parentElement,
      },
    };

    //The statement below returns "true" if the savedRecipe comes from a subset within a workset.
    //In this case, we will add a focusOccurrenceInput to the inputData to let the system know
    //for which subset the filter needs to be reapplied.
    if (
      savedRecipe[selectedObject.uid].focusOccurrenceInput != undefined &&
      savedRecipe[selectedObject.uid].focusOccurrenceInput.element.type ==
        "Fgd0DesignSubsetElement"
    ) {
      inputData.inputData.focusOccurrenceInput =
        savedRecipe[selectedObject.uid].focusOccurrenceInput;
    }
    if (savedRecipe[selectedObject.uid].parentSubsetUID != null) {
      inputData.inputData.parentElement =
        savedRecipe[selectedObject.uid].parentSubsetUID;
    }

    for (
      let jnx = 0;
      jnx < savedRecipe[selectedObject.uid][0].recipe.length;
      jnx++
    ) {
      inputData.inputData.filter.recipe[jnx] =
        savedRecipe[selectedObject.uid][0].recipe[jnx];
    }

    soaSvc
      .post(
        "Internal-ActiveWorkspaceBom-2022-06-OccurrenceManagement",
        "getOccurrences4",
        inputData
      )
      .then(function () {
        deferred.resolve("Recipe restored ");
      });
  }
  return deferred.promise;
}

function resetServerStateAfterVis(ctx, selectedObject) {
  let deferred = AwPromiseService.instance.defer();

  if (savedRecipe[selectedObject.uid].partitionSchemeUid === null) {
    // When 'none' scheme is selected then filters are retained, so nothing to do.
    console.log("> Organization scheme is none");
    deferred.resolve("done");
  } else {
    console.log(
      "> Organization Scheme found: " +
        savedRecipe[selectedObject.uid].partitionSchemeUid
    );
    //console.log('Resetting Org Scheme');

    //let partitionSchemeObject = cdm.getObject(savedRecipe[selectedObject.uid].partitionSchemeUid);

    let productContextUid = ctx.occmgmtContext.productContextInfo.uid; //remove PS(partition scheme) tag from productcontext uid if it exists since we are passing partition scheme inside input data
    if (productContextUid.indexOf("PS:") != -1) {
      let sIndex = productContextUid.indexOf("PS:");
      let eIndex = productContextUid.indexOf(",", sIndex);

      productContextUid = productContextUid.replace(
        productContextUid.substring(sIndex, eIndex + 1),
        ""
      );
    }

    let focusOccInp = "";
    if (selectedObject.type === "Fgd0DesignSubsetElement") {
      focusOccInp = selectedObject.uid;
    } else if (
      appCtxService.getCtx("aceActiveContext.context.topElement.type") ==
        "Awb0DesignElement" &&
      selectedObject.type != "Fgd0DesignSubsetElement"
    ) {
      //in case partition inside subset is visualised.. find the top subset of that partititon and set it as the focusOccurrenceInput
      let startIdx = selectedObject.uid.indexOf("/");
      let endIdx = selectedObject.uid.indexOf("/", startIdx + 1);
      let topElementOfSelectedObjUid = selectedObject.uid.substring(
        startIdx + 1,
        endIdx
      );
      for (let key in ctx.occmgmtContext.elementToPCIMap) {
        if (key.indexOf(topElementOfSelectedObjUid) != -1) {
          focusOccInp = key;
          break;
        }
      }
    }

    let inputData = {
      inputData: {
        config: {
          effectivityDate: "0001-01-01T00:00:00",
          unitNo: -1,
          productContext: {
            type: ctx.occmgmtContext.productContextInfo.type,
            uid: productContextUid,
          },
          occurrenceScheme: {
            uid: savedRecipe[selectedObject.uid].partitionSchemeUid,
            //type: cdm.getObject(savedPartitionSchemeUid).type
          },
        },
        focusOccurrenceInput: {
          element: {
            uid: focusOccInp,
          },
        },
        filter: {
          searchFilterCategories: [],
          searchFilterMap: {},
          fetchUpdatedFilters: false,
          recipe: [],
          searchFilterFieldSortType: "Priority",
          searchSortCriteria: [],
        },
        requestPref: {
          calculateFilters: ["false"],
          displayMode: ["Tree"],
          filterCriteriaOperatorType: ["Include"],
          includePath: ["true"],
          loadTreeHierarchyThreshold: ["50"],
          savedSessionMode: ["restore"],
          startFreshNavigation: ["false"],
          useGlobalRevRule: ["false"],
          viewType: ["BOM"],
        },
        product: {
          uid: ctx.occmgmtContext.topElement.props.awb0UnderlyingObject
            .dbValues[0],
        },
        parentElement: ctx.occmgmtContext.topElement.uid,
      },
    };

    soaSvc
      .post(
        "Internal-ActiveWorkspaceBom-2022-06-OccurrenceManagement",
        "getOccurrences4",
        inputData
      )
      .then(function () {
        deferred.resolve("Scheme reset ");
      });
  }
  return deferred.promise;
}

function createInputForOccurance(
  ctx,
  mselectedArray,
  mselected,
  isRecursion,
  vaasAction,
  deferredMain
) {
  //get Subset Info here first
  let getSubsetInfo_inputData = {
    subsetInputs: [
      {
        productInfo: {
          type: "Awb0ProductContextInfo",
          uid: "",
        },
        recipe: [],
        requestPref: {
          populateFilters: ["true"],
        },
        searchFilterFieldSortType: "",
        searchSortCriteria: [],
      },
    ],
  };
  let currentSubsetFullUid = "";
  let new_productContextInfoString = ctx.occmgmtContext.productContextInfo.uid;
  let local_savedPartitionSchemeUid = "";

  if (mselected.type === "Fgd0DesignSubsetElement") {
    //IF this function is being called as part of the "whole workset" visualization, we
    //need to figure out the correct productContextInfoString from the occmgmtctx.elementToPCIMap
    //If the subset is selected directly, this is not required.
    if (appCtxService.getCtx("selected").uid.indexOf("Cpd0WorksetLine") != -1) {
      new_productContextInfoString =
        ctx.occmgmtContext.elementToPCIMap[mselected.uid];
    }
    currentSubsetFullUid = mselected.uid;
  } else if (mselected.type === "Fgf0PartitionElement") {
    //Check if we are in a Workset and set the product Context info string from the occmgmtContext.elementoPCIMap
    if (
      ctx.occmgmtContext.elementToPCIMapCount != undefined &&
      ctx.occmgmtContext.elementToPCIMapCount > 1
    ) {
      //We need to figure out the parent subset of the selected partition
      //Ex: "SR::N::Fgf0PartitionElement..hGQ5yteyIOVdgA,PR:SR::N::Cpd0WorksetLine..5.0j7DtS04MWO_mC.ATHOWh_vMWePuA..1/h2V9wPza5AChlD/RJU5yteyIOVdgA/,,AWB4GB"
      //                                  ^ Partition                                 ^ Workset                        ^ Subset       ^ Parent Ptn
      //
      // So, the Susbet UID would be the string between the first '/' and second '/'
      let parentSubsetUID = mselected.uid.substring(
        mselected.uid.indexOf("/") + 1,
        mselected.uid.indexOf("/", mselected.uid.indexOf("/") + 1)
      );

      for (let prop in ctx.occmgmtContext.elementToPCIMap) {
        if (prop.indexOf(parentSubsetUID) != "-1") {
          currentSubsetFullUid = prop;
          //new_productContextInfoString = ctx.occmgmtContext.elementToPCIMap[prop];
          break;
        }
      }
      if (new_productContextInfoString == null) {
        console.warn(
          "There was a partition selected in a subset, but we could not get the correct product context info!"
        );
      }
    }
  }

  //save the occurence scheme in let savedPartitionSchemeUid
  if (ctx.selected.type != "Awb0DesignElement") {
    if (
      ctx.occmgmtContext.productContextInfo.props.fgf0PartitionScheme != null &&
      ctx.occmgmtContext.productContextInfo.props.fgf0PartitionScheme !=
        undefined
    ) {
      local_savedPartitionSchemeUid =
        ctx.occmgmtContext.productContextInfo.props.fgf0PartitionScheme
          .dbValues[0];
    }
  } else {
    //if a case of multiple subsets get the PS from the uid
    if (new_productContextInfoString.indexOf("PS") != null) {
      let sIndex = new_productContextInfoString.indexOf("PS:");
      let eIndex = new_productContextInfoString.indexOf(",", sIndex);

      local_savedPartitionSchemeUid = new_productContextInfoString.substring(
        sIndex + 3,
        eIndex
      );
    }
  }

  getSubsetInfo_inputData.subsetInputs[0].productInfo.uid =
    new_productContextInfoString;

  soaSvc
    .post(
      "Internal-ActiveWorkspaceBom-2019-12-OccurrenceManagement",
      "getSubsetInfo3",
      getSubsetInfo_inputData
    )
    .then(function (subsetInfoResponse) {
      //check if there is already a recipe for the currently selected element UID
      if (
        savedRecipe[mselected.uid] != null &&
        savedRecipe[mselected.uid] != undefined
      ) {
        console.log(
          "a recipe for element " + mselected.uid + " was already saved."
        );
      } else {
        savedRecipe[mselected.uid] = JSON.parse(
          JSON.stringify(subsetInfoResponse.filterOut)
        ); //JSON-stringify + parse to make a copy of the object
        if (savedRecipe[mselected.uid] != null) {
          if (currentSubsetFullUid != "") {
            savedRecipe[mselected.uid].parentSubsetUID = currentSubsetFullUid;
          } else {
            savedRecipe[mselected.uid].parentSubsetUID =
              ctx.occmgmtContext.topElement.uid;
          }
          console.log("recipe saved");
        }
      }
      savedRecipe[mselected.uid].partitionSchemeUid =
        local_savedPartitionSchemeUid;
      //console.log("Response of getSubsetInfo2: " + JSON.stringify(subsetInfoResponse));

      //we will construct a default input data set first and then override the recipe and searchfilters if needed.
      let inputData = {
        inputData: {
          product: {
            uid: ctx.pselected.props.awb0UnderlyingObject.dbValues[0],
            //type: ctx.pselected.props.awb0UnderlyingObjectType.dbValues[0]
          },
          focusOccurrenceInput: {},
          //Markus Gloeckl: Parent element is the parent for which we want to fetch child occurrences. In this case it should be the selected
          //partition or selected subset.
          parentElement: mselected.uid,
          config: {
            productContext: {
              uid: new_productContextInfoString,
              type: "Awb0ProductContextInfo",
            },
            occurrenceScheme: {
              uid: "noneObject",
              type: "unknownType",
            },
            effectivityDate: "0001-01-01T00:00:00",
            unitNo: -1,
          },
          filter: {
            recipe: [],

            searchFilterMap: {},
            searchSortCriteria: [],
            searchFilterFieldSortType: "Priority",
            fetchUpdatedFilters: false,
          },
          requestPref: {
            calculateFilters: ["false"],
            //includePath: ["true"],
            //populateFilters: ["true"],
            savedSessionMode: ["restore"],
            //loadTreeHierarchyThreshold: ["100"],
            //startFreshNavigation: [(new Boolean(!isRecursion)).toString()],
            startFreshNavigation: ["false"],
            useGlobalRevRule: ["false"],
            //filterCriteriaOperatorType: ["Filter"],
            //viewType: ["BOM"], //Keep user selection
            displayMode: ["Tree"],
            unsetPartitionScheme: ["true"],
            //firstLevelOnly: ["true"]
          },
          cursor: {
            pageSize: 100,
            startReached: false,
          },
        },
      };

      //If the parent element is empty, set it from ctx.occmgmtcontext
      //This would be the case if the user is in a Product (CD, Workset, Subset Defn) and there is nothing selected in the UI
      if (inputData.inputData.parentElement == null) {
        inputData.inputData.parentElement =
          ctx.occmgmtContext.openedElement.uid;
      }

      //If the user has selected a partition within a subset, replace the parentElement with the Subset UID
      if (currentSubsetFullUid != "") {
        inputData.inputData.parentElement = currentSubsetFullUid;
      }

      //If the top line element is a Collaborative Design, clear the recipe. Only pass filters
      if (ctx.occmgmtContext.topElement.type === "Fgf0ApplicationModel") {
        //inputData.inputData.filter.recipe = [];

        //if nothing is selected, e.g. selected element is Fgf0ApplicationModel AND
        //if the recipe in the subset info panel is empty, we need to add a global search for PartUsage.posvar_name = "*"

        if (
          subsetInfoResponse.filterOut[0].recipe.length == 0 ||
          subsetInfoResponse.filterOut[0].recipe == null
        ) {
          inputData.inputData.filter.searchFilterCategories = [
            {
              categoryType: "Attribute",
              defaultFilterValueDisplayCount: 0,
              displayName: "PosVar name",
              editable: true,
              internalName: "__AW_G4B_PartUsage_Subset.posvar_name",
              isHierarchical: false,
              isMultiSelect: false,
              quickSearchable: false,
            },
          ];

          inputData.inputData.filter.searchFilterMap = {
            "__AW_G4B_PartUsage_Subset.posvar_name": [
              {
                count: 0,
                endDateValue: "",
                endNumericValue: 0,
                hasChildren: false,
                searchFilterType: "Attribute",
                selected: true,
                startDateValue: "",
                startEndRange: "",
                startNumericValue: 0,
                stringDisplayValue: "",
                stringValue: "*",
              },
            ],
          };
        }
      }

      //Save current filter before partition is called and then restore it after occurrences have been returned
      if (mselected.type == "Fgf0PartitionElement") {
        // let partitionRecipeTerm = {
        //     criteriaType: "Partition",
        //     criteriaDisplayValue: "PDMVis Partition Filter",
        //     criteriaOperatorType: "Filter",
        //     criteriaValues: [],
        //     subCriteria: []
        // }

        let partitionScheme = "Ptn0PartitionScheme.G4B_PartnSchemeMMGFB";
        if (
          mselected.props.awb0UnderlyingObjectType.dbValues[0] ==
          "G4B_PartitionMMGFB"
        ) {
          partitionScheme = "Ptn0PartitionScheme.G4B_PartnSchemeMMGFB";
        } else if (
          mselected.props.awb0UnderlyingObjectType.dbValues[0] ==
            "G4B_PartnKovAPI" ||
          mselected.props.awb0UnderlyingObjectType.dbValues[0] ==
            "G4B_PartnKovAPILO" ||
          mselected.props.awb0UnderlyingObjectType.dbValues[0] ==
            "G4B_PartnKovANoPI"
        ) {
          partitionScheme = "Ptn0PartitionScheme.G4B_PartnSchemeKovAPI";
        } else if (
          mselected.props.awb0UnderlyingObjectType.dbValues[0] ==
          "G4B_PartnKovASC"
        ) {
          partitionScheme = "Ptn0PartitionScheme.G4B_PartnSchemeKovASC";
        }

        let searchFilterCategories = [
          {
            categoryType: "Partition",
            defaultFilterValueDisplayCount: 0,
            displayName: "",
            editable: true,
            internalName: partitionScheme,
            isHierarchical: false,
            isMultiSelect: false,
            quickSearchable: false,
          },
        ];

        let partitionRecipeTerm = {
          criteriaDisplayValue: "MMG-FB_$CAT_PDMVIS_PARTITION_FILTER",
          criteriaOperatorType: "Filter",
          criteriaType: "Partition",
          criteriaValues: [],
          subCriteria: [],
        };

        for (let k = 0; k < mselectedArray.length; k++) {
          let searchFilterMapToAdd = {
            count: 0,
            endDateValue: "",
            endNumericValue: 0,
            hasChildren: false,
            searchFilterType: "Partition",
            selected: true,
            startDateValue: "",
            startEndRange: "",
            startNumericValue: 0,
            stringDisplayValue: "dummy",
            stringValue:
              mselectedArray[k].props.awb0UnderlyingObject.dbValues[0],
          };

          if (
            inputData.inputData.filter.searchFilterMap[partitionScheme] == null
          ) {
            inputData.inputData.filter.searchFilterMap[partitionScheme] = [];
          }
          inputData.inputData.filter.searchFilterMap[partitionScheme].push(
            searchFilterMapToAdd
          );

          partitionRecipeTerm.criteriaValues.push(
            mselectedArray[k].props.awb0UnderlyingObject.dbValues[0]
          );
        }
        partitionRecipeTerm.criteriaValues.push("1");

        inputData.inputData.requestPref.filterCriteriaOperatorType = ["Filter"];
        inputData.inputData.filter.searchFilterCategories =
          searchFilterCategories;
        inputData.inputData.filter.recipe =
          subsetInfoResponse.filterOut[0].recipe;
        inputData.inputData.filter.recipe.push(partitionRecipeTerm);
      }

      if (isRecursion) {
        inputData.inputData.cursor = {
          cursorData: appCtxService.getCtx("cursorData" + mselected.uid),
          endIndex: appCtxService.getCtx("endIndex" + mselected.uid),
          pageSize: 100,
          startReached: true,
        };
        inputData.inputData.filter.searchFilterCategories = [];
        inputData.inputData.filter.searchFilterMap = {};
        inputData.inputData.filter.recipe = [];
        inputData.inputData.focusOccurrenceInput = {};
        inputData.inputData.parentElement = mselected.uid;
      }

      _callServerForOccurences(
        inputData,
        mselectedArray,
        mselected,
        isRecursion,
        vaasAction,
        deferredMain
      );
    });
}

function _callServerForOccurences(
  inputData,
  mselectedArray,
  selectedObject,
  isRecursion,
  vaasAction,
  deferredMain
) {
  let policyId = propPolicySvc.register({
    types: [
      // {
      //     name: "Fgd0DesignElement",
      //     properties: [{
      //         name: "occurenceId"
      //     }, {
      //         name: "underlyingObjectType"
      //     }]
      // },
      {
        name: "Fgb0PartUsage",
        properties: [
          {
            name: "fgb0DesignComponent",
          },
        ],
      },
      {
        name: "G4B_AbsDesignElement",
        properties: [
          {
            name: "occurenceId",
          },
          {
            name: "underlyingObjectType",
          },
          {
            name: "awb0UnderlyingObject",
          },
        ],
      },
    ],
  });
  soaSvc
    .post(
      "Internal-ActiveWorkspaceBom-2022-06-OccurrenceManagement",
      "getOccurrences4",
      inputData
    )
    .then(function (response) {
      propPolicySvc.unregister(policyId);
      // data.allProducts = response.occurrences;
      //data.totalFound = response.occurrences.length;
      //data.endIndex = response.cursor.endIndex;
      //data.endReached = response.cursor.endReached;
      //data.pageSize = response.cursor.pageSize;
      //data.cursorData = response.cursor.cursorData;

      // let inputDataForGetProp = {};
      // inputDataForGetProp.objects = response.occurrences;
      // inputDataForGetProp.attributes = ["awb0UnderlyingObject"];
      // soaSvc.post("Core-2006-03-DataManagement", "getProperties", inputDataForGetProp).then(
      //     function (response1) {
      console.log("getOccurrences7 Server response for: " + selectedObject.uid);
      createVaaSInputNew(
        response,
        mselectedArray,
        selectedObject,
        isRecursion,
        vaasAction,
        deferredMain
      );
      //});
    });
}

function createVaaSInputNew(
  response,
  mselectedArray,
  selectedObject,
  isRecursion,
  vaasAction,
  deferredMain
) {
  let deferred = AwPromiseService.instance.defer();
  //Open VaaS in a popup window
  let promise2 = openVaaSWindow();

  //On the first run of this function, we set a global variable to indicate that there may be
  //recursive function calls.
  //On the first run, any previously existing inputMaps or endIndex will be reset.

  if (isRecursion == false) {
    //Reset the VaaS scene
    //This variable is used to synchronize parallel running jobs for multiple subsets. E.g. Job2 should not be able to reset the scene that job 1 has set up
    if (vaasResetRequired == true) {
      resetVaaSScene();
      let counter = 0;
      setTimeout(function () {
        checkVaaSAvailable(deferred, counter);
      }, 1000);
    } else {
      deferred.resolve("no reset needed.");
    }
  } else {
    deferred.resolve("no reset needed.");
  }
  //set parameters for endIndex and cursorData
  if (appCtxService.getCtx("endIndex" + selectedObject.uid) == undefined) {
    appCtxService.registerCtx(
      "endIndex" + selectedObject.uid,
      response.cursor.endIndex
    );
  } else {
    appCtxService.updateCtx(
      "endIndex" + selectedObject.uid,
      appCtxService.getCtx("endIndex" + selectedObject.uid) + 100
    );
  }

  if (
    response.cursor.endReached != undefined &&
    response.cursor.endReached == false
  ) {
    if (appCtxService.getCtx("cursorData" + selectedObject.uid) == undefined) {
      appCtxService.registerCtx(
        "cursorData" + selectedObject.uid,
        response.cursor.cursorData
      );
    } else {
      appCtxService.updateCtx(
        "cursorData" + selectedObject.uid,
        response.cursor.cursorData
      );
    }
  }

  $.when(deferred.promise, promise2).done(function () {
    let promise = createVaaSInput(response, selectedObject, vaasAction);
    $.when(promise).done(function () {
      console.log("Returned from inner function _createVaaSInput");

      //Check if the end of the Product Structure is reached
      if (
        (response.cursor.endReached != undefined &&
          response.cursor.endReached == true) ||
        appCtxService.getCtx("g4b_forceStopPDMVisByUserFlag") == true
      ) {
        if (appCtxService.getCtx("g4b_forceStopPDMVisByUserFlag") == true) {
          appCtxService.unRegisterCtx("g4b_forceStopPDMVisByUserFlag");
          deferredMain.resolve();
        }
        console.log(
          "Loading of Configured Structure for VaaS complete! for " +
            selectedObject.uid
        );
        //If endReached == true -> unRegisterCtx for cursor data
        if (
          appCtxService.getCtx("cursorData" + selectedObject.uid) != undefined
        ) {
          appCtxService.unRegisterCtx(
            "cursorData" + selectedObject.uid,
            response.cursor.cursorData
          );
        }
        if (
          appCtxService.getCtx("endIndex" + selectedObject.uid) != undefined
        ) {
          appCtxService.unRegisterCtx(
            "endIndex" + selectedObject.uid,
            response.cursor.endIndex
          );
        }

        //update the appCtx with the current opened product UID. This is used to hide the buttons for some vaas functionality when the
        //user navigates to a different product and has not visualised that yet
        if (appCtxService.getCtx("vaasProductUID") == undefined) {
          appCtxService.registerCtx(
            "vaasProductUID",
            appCtxService.getCtx("occmgmtContext").topElement.uid
          );
        } else {
          appCtxService.updateCtx(
            "vaasProductUID",
            appCtxService.getCtx("occmgmtContext").topElement.uid
          );
        }

        let promise3 = {};
        if (savedRecipe[selectedObject.uid][0].recipe.length === 0) {
          // if recipe is empty then remove the filter applied during vass visualization
          console.log(
            "Saved Recipe before PDMVis Call was empty, so we are resetting it again now."
          );
          promise3 = removeFilterFromPartition(appCtxService.ctx);
        } else {
          console.log("Saved Recipe found, restoring it now.");
          promise3 = reapplyFilterAfterVis(appCtxService.ctx, selectedObject);
        }
        $.when(promise3).done(function () {
          console.log("Filter update");
          let promise4 = resetServerStateAfterVis(
            appCtxService.ctx,
            selectedObject
          );
          $.when(promise4).done(function () {
            console.log(
              "Restored server state after visualtization is complete."
            );
            deferredMain.resolve();
          });
        });

        throw "Loading of Configured Structure for VaaS complete!";
        //}
        //
      } else {
        console.log("Looping for " + selectedObject.uid);
        createInputForOccurance(
          appCtxService.ctx,
          mselectedArray,
          selectedObject,
          true,
          vaasAction,
          deferredMain
        );
      }
    });
  });
}

function createVaaSInput(response, selectedObject, vaasAction) {
  let deferred = AwPromiseService.instance.defer();
  let allDesignElements = [];
  let innerFunctionsDone = false;
  let inputData = {};
  let arrayNodeIds = [];
  let designElementUIDs = [];

  //load objects first
  let occurrence_DE_UIDs = [];
  for (let k = 0; k < response.parentChildrenInfos.length; k++) {
    for (
      let i = 0;
      i < response.parentChildrenInfos[k].childrenInfo.length;
      i++
    ) {
      if (
        response.parentChildrenInfos[k].childrenInfo[i].occurrenceId.indexOf(
          "SR::N::Fgb0PartUsage"
        ) == -1
      ) {
        continue;
      }
      designElementUIDs.push(
        response.parentChildrenInfos[k].childrenInfo[i].occurrenceId
      );
      let occurrence_DE_UID = response.parentChildrenInfos[k].childrenInfo[
        i
      ].occurrenceId.substring(
        response.parentChildrenInfos[k].childrenInfo[i].occurrenceId.indexOf(
          "bom0DesignElement:"
        ) + 18, //18 is the length of >bom0DesignElement:<
        response.parentChildrenInfos[k].childrenInfo[i].occurrenceId.indexOf(
          ",",
          response.parentChildrenInfos[k].childrenInfo[i].occurrenceId.indexOf(
            "bom0DesignElement:"
          ) + 18
        )
      );
      if (occurrence_DE_UID != undefined && occurrence_DE_UID.length > 0) {
        occurrence_DE_UIDs.push(occurrence_DE_UID);
      }
    }
  }

  if (occurrence_DE_UIDs.length == 0) {
    innerFunctionsDone = true;
    console.log("Value of innerFunctionsDone = " + innerFunctionsDone);
    deferred.resolve("Inner function done.");
  } else {
    inputData = {
      uids: occurrence_DE_UIDs,
    };
    soaSvc
      .post("Core-2007-09-DataManagement", "loadObjects", inputData)
      .then(function (loadObjsResponse) {
        for (let prop in loadObjsResponse.modelObjects) {
          if (loadObjsResponse.modelObjects[prop].type != "G4B_DesignElement") {
            continue;
          }

          allDesignElements.push(
            cdm.getObject(loadObjsResponse.modelObjects[prop].uid)
          );
        }

        // for (i = 0; i < totalOcurranceFound; i++) {
        //     if (response.occurrences[i].occurrence.type === "Fgd0DesignElement") {
        //         let singleDE = cdm.getObject(response.occurrences[i].occurrence.props.awb0UnderlyingObject.dbValues[0]);
        //         if (singleDE != null) {
        //             allDesignElements.push(singleDE);
        //             designElementUIDs.push(response.occurrences[i].occurrence.uid);
        //         }
        //     }
        //     if (response.occurrences[i].occurrence.type === "Fgb0PartUsage") {
        //         let singleDE = cdm.getObject(response.occurrences[i].occurrence.props.fgb0DesignComponent.dbValues[0]);
        //         if (singleDE != null) {
        //             allDesignElements.push(singleDE);
        //             designElementUIDs.push(response.occurrences[i].occurrence.uid);
        //         }
        //     }
        // }

        if (allDesignElements != null && allDesignElements.length > 0) {
          console.log("Design elements found = " + allDesignElements.length);
          inputData = {
            objects: allDesignElements,
            attributes: ["cpd0subordinates"],
          };
          soaSvc
            .post("Core-2006-03-DataManagement", "getProperties", inputData)
            .then(function () {
              let len = allDesignElements.length;
              for (let i = 0; i < len; i++) {
                //check for subordinate DEs
                if (
                  allDesignElements[i].props.cpd0subordinates &&
                  allDesignElements[i].props.cpd0subordinates.dbValues.length >
                    0
                ) {
                  for (
                    let ii = 0;
                    ii <
                    allDesignElements[i].props.cpd0subordinates.dbValues.length;
                    ii++
                  ) {
                    let subordinateDE = cdm.getObject(
                      allDesignElements[i].props.cpd0subordinates.dbValues[ii]
                    );
                    if (
                      designElementUIDs.indexOf(
                        "subordinateDE_" +
                          allDesignElements[i].props.cpd0subordinates.dbValues[
                            ii
                          ]
                      ) == -1
                    ) {
                      allDesignElements.push(subordinateDE);
                      designElementUIDs.push(
                        "subordinateDE_" +
                          allDesignElements[i].props.cpd0subordinates.dbValues[
                            ii
                          ]
                      );
                    }
                  }
                }
              }

              inputData.objects = allDesignElements;
              inputData.attributes = [
                "cpd0design_element_id",
                "g4b_PEP_DOKID",
                "mdl0absolute_transform",
              ];
              soaSvc
                .post("Core-2006-03-DataManagement", "getProperties", inputData)
                .then(function () {
                  let vaaslist = JSON.parse(
                    sessionStorage.getItem("vaasInVisulationList")
                  );
                  if (vaaslist != null) {
                    sessionCacheForVaasVisulalisation = vaaslist;
                  }
                  for (let i = 0; i < allDesignElements.length; i++) {
                    if (
                      allDesignElements[i].props.g4b_PEP_DOKID.dbValues[0] ==
                        null &&
                      window.location.hostname === "localhost"
                    ) {
                      allDesignElements[i].props.g4b_PEP_DOKID.dbValues[0] =
                        Math.floor(
                          Math.random() * Math.floor(999999)
                        ).toString();
                    }

                    if (
                      allDesignElements[i].props.g4b_PEP_DOKID.dbValues[0] !=
                      null
                    ) {
                      //check: has the current Design Element ID already been added to the map?
                      if (vaaslist.length > 0) {
                        let nodeInformation = existsDEinVaaSContext(
                          vaaslist,
                          allDesignElements[i].props.g4b_PEP_DOKID.dbValues[0],
                          allDesignElements[
                            i
                          ].props.mdl0absolute_transform.dbValues.toString()
                        );
                        if (nodeInformation != undefined) {
                          //console.log("Debug: Node was already on the cache: " + allDesignElements[i].props.object_name.uiValues[0] + " \nNode ID: " + nodeInformation.nodeId);

                          if (vaasAction == VAAS_ACTION.toggle) {
                            if (nodeInformation.visibility == false) {
                              vaasPopupWindow.webvis.setProperty(
                                nodeInformation.nodeId,
                                "enabled",
                                1
                              );
                              nodeInformation.visibility = true;
                            } else if (nodeInformation.visibility == true) {
                              vaasPopupWindow.webvis.setProperty(
                                nodeInformation.nodeId,
                                "enabled",
                                0
                              );
                              nodeInformation.visibility = false;
                            }
                            updateJsonVisCacheElement(
                              sessionCacheForVaasVisulalisation,
                              nodeInformation.nodeId,
                              nodeInformation
                            );
                            continue;
                          } else {
                            //vaasAction == VAAS_ACTION.showOnly (in this case continue the parent loop for next DE)
                            continue;
                          }
                        }
                        //endIf nodeInformation != undefined
                      }
                      try {
                        let localNodeId = vaasPopupWindow.webvis.add(
                          "urn:bmw:prisma:dokuid:" +
                            allDesignElements[i].props.g4b_PEP_DOKID.dbValues[0]
                        );
                        arrayNodeIds.push(localNodeId);
                        vaasPopupWindow.webvis.setProperty(
                          localNodeId,
                          "localTransform",
                          allDesignElements[i].props.mdl0absolute_transform
                            .dbValues
                        );

                        //Need to find the matching Occurrence Management UID for the DesignElement
                        let localDesignElementUID = "";
                        for (let j = 0; j < designElementUIDs.length; j++) {
                          if (
                            designElementUIDs[j].indexOf(
                              allDesignElements[i].uid
                            ) != -1
                          ) {
                            localDesignElementUID = designElementUIDs[j];
                            break;
                          }
                        }

                        let vaasObject = {
                          nodeId: localNodeId,
                          dokuId:
                            allDesignElements[i].props.g4b_PEP_DOKID
                              .dbValues[0],
                          transformMatrix:
                            allDesignElements[
                              i
                            ].props.mdl0absolute_transform.dbValues.toString(),
                          value: localNodeId,
                          uid: localDesignElementUID,
                          visibility: true,
                        };
                        sessionCacheForVaasVisulalisation.push(vaasObject);

                        array_DesignElementsToVaaS.push(vaasObject);
                      } catch (ex) {
                        console.error(
                          "Exception occured while adding a Design Element to VaaS viewer: " +
                            ex
                        );
                      }
                      // j++;
                    }
                  }

                  //Json.stringify all VaaS DE's after the current loop (better performance)
                  sessionStorage.setItem(
                    "vaasInVisulationList",
                    JSON.stringify(sessionCacheForVaasVisulalisation)
                  );

                  //batch-enable all nodeIds in VaaS
                  vaasPopupWindow.webvis.setProperty(
                    arrayNodeIds,
                    "enabled",
                    1
                  );
                  arrayNodeIds = [];
                  innerFunctionsDone = true;
                  console.log(
                    "Value of innerFunctionsDone = " + innerFunctionsDone
                  );
                  deferred.resolve("Inner function done.");
                });
            });
        }
      });
  }
  return deferred.promise;
}

/**
 * This function will loop through the object 'elementToPCIMap'
 */
export let getPartitionSchemeUID_StaticProduct = function (data, ctx) {
  let partitionSchemeUID = "none";
  for (let key in ctx.occmgmtContext.elementToPCIMap) {
    if (ctx.occmgmtContext.elementToPCIMap[key].indexOf(",PS:") != -1) {
      partitionSchemeUID = ctx.occmgmtContext.elementToPCIMap[key].substring(
        ctx.occmgmtContext.elementToPCIMap[key].indexOf(",PS:") + 4,
        ctx.occmgmtContext.elementToPCIMap[key].indexOf(
          ",",
          ctx.occmgmtContext.elementToPCIMap[key].indexOf(",PS:") + 1
        )
      );
      break;
    }
  }

  appCtxService.registerCtx("scp_partitionschemeUID", partitionSchemeUID);
};

export let getAllProductsUnderContext6 = function (
  data,
  ctx,
  vaasAction,
  isCalledFromWithinService
) {
  let deferredMain = AwPromiseService.instance.defer();

  handlingAWSelectionEvent = false;

  if (isCalledFromWithinService == undefined) {
    isCalledFromWithinService = false;
  }

  if (
    isCalledFromWithinService == false &&
    vaasAction == VAAS_ACTION.showOnly
  ) {
    vaasResetRequired = true;

    savedRecipe = {};
    array_DesignElementsToVaaS = [];
    /*************************** Hide and Show in VaaS  ********************************/
    //Partation Cache intialized
    localPartitionCache = [];

    let partition = {
      partition: localPartitionCache,
    };
    sessionStorage.setItem("partitionStorage", JSON.stringify(partition));

    //Visuliase In Vaas List Initialized

    sessionCacheForVaasVisulalisation = [];
    sessionStorage.setItem(
      "vaasInVisulationList",
      JSON.stringify(sessionCacheForVaasVisulalisation)
    );

    /*******************************END*************************************************************** */
  } else if (vaasAction == VAAS_ACTION.toggle) {
    vaasResetRequired = false;
  }

  //Since the user can select multiple partitions and part usages at the same time, we want to give them
  //maximum flexibility. This is why we're sorting partitions and PUs into separate arrays.
  //Partitions will be added to the temporary filter and PUs sent to another function for individual visualization
  let arrSelectedPartitions = [];
  let arrSelectedPartUsages = [];
  let arrSelectedSubsets = [];
  let arrRemainingMSelectedObjs = [];
  ctx.mselected.forEach(function (element) {
    if (element.type == "Fgf0PartitionElement") {
      arrSelectedPartitions.push(element);
    } else if (element.type == "Fgb0PartUsage") {
      arrSelectedPartUsages.push(element);
    } else if (element.type == "Fgd0DesignSubsetElement") {
      arrSelectedSubsets.push(element);
    } else {
      arrRemainingMSelectedObjs.push(element);
    }
  });

  //send individually selected Part Usages to other function for visualization
  if (arrSelectedPartUsages.length > 0) {
    exports.visualizeMultipleDesignElement(
      arrSelectedPartUsages,
      true,
      vaasAction,
      deferredMain
    );
  }

  //let deferreds = [];
  let i = 0;
  for (
    i = 0;
    i < arrSelectedSubsets.length;
    i++ // loop for each subset and get the Design elements
  ) {
    console.log("Process starts for subset: " + arrSelectedSubsets[i].uid);
    createInputForOccurance(
      ctx,
      arrSelectedSubsets,
      arrSelectedSubsets[i],
      false,
      vaasAction,
      deferredMain
    );
  }
  let sortedPartitions = sortFgf0PartitionElementBySubsets(
    ctx,
    arrSelectedPartitions
  );

  if (sortedPartitions != null) {
    for (let prop in sortedPartitions) {
      //For all other cases
      console.log(
        "Process starts for selected partitions in AppModel / Subset:  " + prop
      );

      createInputForOccurance(
        ctx,
        sortedPartitions[prop],
        sortedPartitions[prop][0],
        false,
        vaasAction,
        deferredMain
      );
    }
  }

  if (arrRemainingMSelectedObjs.length > 0) {
    //Fgf0ApplicationModel, Mdl0SubsetDefinition, etc..
    console.log("Process starts for object: " + ctx.mselected[0].uid);
    createInputForOccurance(
      ctx,
      arrRemainingMSelectedObjs,
      arrRemainingMSelectedObjs[0],
      false,
      vaasAction,
      deferredMain
    );
  }

  return deferredMain.promise;
};

function sortFgf0PartitionElementBySubsets(ctx, fgf0PartitionElementArray) {
  let i = 0;
  let parentSubsetUID = "";
  let returnStruct = {};

  if (
    fgf0PartitionElementArray == null ||
    fgf0PartitionElementArray.length == 0
  ) {
    return null;
  }

  if (ctx.occmgmtContext.topElement.uid.indexOf("Cpd0WorksetLine") == -1) {
    returnStruct[
      ctx.occmgmtContext.topElement.props.awb0UnderlyingObject.dbValues[0]
    ] = fgf0PartitionElementArray;
    return returnStruct;
  }

  for (i = 0; i < fgf0PartitionElementArray.length; i++) {
    //We need to figure out the parent subset of the selected partition
    //Ex: "SR::N::Fgf0PartitionElement..hGQ5yteyIOVdgA,PR:SR::N::Cpd0WorksetLine..5.0j7DtS04MWO_mC.ATHOWh_vMWePuA..1/h2V9wPza5AChlD/RJU5yteyIOVdgA/,,AWB4GB"
    //                                  ^ Partition                                 ^ Workset                        ^ Subset       ^ Parent Ptn
    //
    // So, the Susbet UID would be the string between the first '/' and second '/'
    parentSubsetUID = fgf0PartitionElementArray[i].uid.substring(
      fgf0PartitionElementArray[i].uid.indexOf("/") + 1,
      fgf0PartitionElementArray[i].uid.indexOf(
        "/",
        fgf0PartitionElementArray[i].uid.indexOf("/") + 1
      )
    );

    if (returnStruct[parentSubsetUID] == null) {
      returnStruct[parentSubsetUID] = [];
    }
    returnStruct[parentSubsetUID].push(fgf0PartitionElementArray[i]);
  }
  return returnStruct;
}

// eslint-disable-next-line no-unused-vars
export let createInputForOccuranceForWorkset = function (ctx, mselected) {
  let deferredMain = AwPromiseService.instance.defer();
  /*************************** Hide and Show in VaaS  ********************************/
  //Partation Cache intialized
  localPartitionCache = [];
  array_DesignElementsToVaaS = [];

  handlingAWSelectionEvent = false;

  let partition = {
    partition: localPartitionCache,
  };
  sessionStorage.setItem("partitionStorage", JSON.stringify(partition));

  //Visuliase In Vaas List Initialized
  sessionCacheForVaasVisulalisation = [];
  sessionStorage.setItem(
    "vaasInVisulationList",
    JSON.stringify(sessionCacheForVaasVisulalisation)
  );
  /*******************************END*************************************************************** */

  let subsetArray = [];
  let inputData = {
    inputData: {
      product: {
        uid: ctx.pselected.props.awb0UnderlyingObject.dbValues[0],
        //type: ctx.pselected.props.awb0UnderlyingObjectType.dbValues[0]
      },
      parentElement: ctx.occmgmtContext.topElement.uid,
      config: {
        productContext: {
          uid: ctx.occmgmtContext.productContextInfo.uid,
          type: ctx.occmgmtContext.productContextInfo.type,
        },
      },
      filter: {
        recipe: [],
        searchFilterCategories: [],
        searchFilterMap: {},
        searchSortCriteria: [],
        searchFilterFieldSortType: "Priority",
        fetchUpdatedFilters: false,
      },
      cursor: {
        pageSize: 100,
      },
    },
  };

  soaSvc
    .post(
      "Internal-ActiveWorkspaceBom-2022-06-OccurrenceManagement",
      "getOccurrences4",
      inputData
    )
    .then(function (response) {
      for (
        let i = 0;
        i < response.parentChildrenInfos[0].childrenInfo.length;
        i++
      ) {
        if (
          response.parentChildrenInfos[0].childrenInfo[i].occurrence.type ===
          "Fgd0DesignSubsetElement"
        ) {
          subsetArray.push(
            response.parentChildrenInfos[0].childrenInfo[i].occurrence
          );
        }
      }
      vaasResetRequired = true;

      //reset the saved recipe object before starting visualization for a workset
      savedRecipe = {};

      let deferreds = [];
      for (
        let i = 0;
        i < subsetArray.length;
        i++ // loop for each subset and get the Design elements
      ) {
        let tempArr = [subsetArray[i]];
        ctx.mselected = tempArr;
        deferreds.push(
          exports.getAllProductsUnderContext6(
            tempArr,
            ctx,
            VAAS_ACTION.showOnly,
            true
          )
        );
      }
      $.when.apply($, deferreds).then(
        //execute multiple visInVaas tasks in parallel
        function () {
          console.log("Deferreds done");
          deferredMain.resolve();
        }
      );
    });
  return deferredMain.promise;
};

/*Author: Shalini Muralidharan
 * Description: Visualize Multiple Design Elements in VaaS
 */
export let visualizeMultipleDesignElement = function (
  selectedElements,
  isCalledFromWithinService,
  vaasAction,
  deferredMain
) {
  //console.log("mselected->",selectedElement);

  let inputData = {};
  let selectedObject = [];
  let designElementUIDs = [];
  let vaasInput;

  handlingAWSelectionEvent = false;

  if (
    isCalledFromWithinService == undefined ||
    isCalledFromWithinService == false
  ) {
    vaasResetRequired = true;
  }

  inputData.objects = selectedElements;
  inputData.attributes = ["fgb0DesignComponent", "awb0UnderlyingObject"];

  soaSvc
    .post("Core-2006-03-DataManagement", "getProperties", inputData)
    .then(function () {
      inputData = {};
      for (let count = 0; count < selectedElements.length; count++) {
        if (
          selectedElements[count].props.fgb0DesignComponent != undefined &&
          selectedElements[count].props.fgb0DesignComponent.dbValues[0] != ""
        ) {
          selectedObject.push(
            cdm.getObject(
              selectedElements[count].props.fgb0DesignComponent.dbValues[0]
            )
          );
          designElementUIDs.push(selectedElements[count].uid);
        } else if (
          selectedElements[count].props.awb0UnderlyingObject != undefined &&
          selectedElements[count].props.awb0UnderlyingObject.dbValues[0] != ""
        ) {
          if (
            cdm.getObject(
              selectedElements[count].props.awb0UnderlyingObject.dbValues[0]
            ).type == "Fgd0DesignElement"
          ) {
            selectedObject.push(
              cdm.getObject(
                selectedElements[count].props.awb0UnderlyingObject.dbValues[0]
              )
            );
            designElementUIDs.push(selectedElements[count].uid);
          }
        }
      }
      let deferred = AwPromiseService.instance.defer();

      //Open VaaS in a popup window
      let promise_openWindow = openVaaSWindow();
      if (vaasResetRequired == true) {
        //Reset the VaaS scene
        resetVaaSScene();
        let counter = 0;
        setTimeout(function () {
          checkVaaSAvailable(deferred, counter);
        }, 1000);
      } else {
        deferred.resolve("no reset needed.");
      }

      //update the appCtx with the current opened product UID. This is used to hide the buttons for some vaas functionality when the
      //user navigates to a different product and has not visualised that yet
      if (appCtxService.getCtx("vaasProductUID") == undefined) {
        appCtxService.registerCtx(
          "vaasProductUID",
          appCtxService.getCtx("occmgmtContext").topElement.uid
        );
      } else {
        appCtxService.updateCtx(
          "vaasProductUID",
          appCtxService.getCtx("occmgmtContext").topElement.uid
        );
      }

      $.when(deferred.promise, promise_openWindow).done(function () {
        //get the subordinate Design Elements (if there are any)
        inputData.objects = selectedObject;
        inputData.attributes = [
          "cpd0subordinates",
          "g4b_PEP_DOKID",
          "mdl0absolute_transform",
        ];
        soaSvc
          .post("Core-2006-03-DataManagement", "getProperties", inputData)
          .then(function () {
            let len = selectedObject.length;
            for (let i = 0; i < len; i++) {
              //check for subordinate DEs
              if (
                selectedObject[i].props.cpd0subordinates &&
                selectedObject[i].props.cpd0subordinates.dbValues.length > 0
              ) {
                for (
                  let ii = 0;
                  ii < selectedObject[i].props.cpd0subordinates.dbValues.length;
                  ii++
                ) {
                  let subordinateDE = cdm.getObject(
                    selectedObject[i].props.cpd0subordinates.dbValues[ii]
                  );
                  if (
                    designElementUIDs.indexOf(
                      "subordinateDE_" +
                        selectedObject[i].props.cpd0subordinates.dbValues[ii]
                    ) == -1
                  ) {
                    selectedObject.push(subordinateDE);
                    designElementUIDs.push(
                      "subordinateDE_" +
                        selectedObject[i].props.cpd0subordinates.dbValues[ii]
                    );
                  }
                }
              }
            }

            //check to see if there is already a session cache before overwriting
            let vaaslist = JSON.parse(
              sessionStorage.getItem("vaasInVisulationList")
            );
            if (vaaslist != null) {
              sessionCacheForVaasVisulalisation = vaaslist;
            }

            if (vaasAction == VAAS_ACTION.toggle) {
              selectedObject.forEach(function (element) {
                //check if the PU/DE is already on the cache
                let nodeInformation = existsDEinVaaSContext(
                  sessionCacheForVaasVisulalisation,
                  element.props.g4b_PEP_DOKID.dbValues[0],
                  element.props.mdl0absolute_transform.dbValues.toString()
                );
                if (nodeInformation != undefined) {
                  if (vaasAction == VAAS_ACTION.toggle) {
                    if (nodeInformation.visibility == false) {
                      vaasPopupWindow.webvis.setProperty(
                        nodeInformation.nodeId,
                        "enabled",
                        1
                      );
                      nodeInformation.visibility = true;
                      console.log(
                        "Toggling property enabled = 1 for nodeId: ",
                        nodeInformation.nodeId
                      );
                    } else if (nodeInformation.visibility == true) {
                      vaasPopupWindow.webvis.setProperty(
                        nodeInformation.nodeId,
                        "enabled",
                        0
                      );
                      nodeInformation.visibility = false;
                      console.log(
                        "Toggling property enabled = 1 for nodeId: ",
                        nodeInformation.nodeId
                      );
                    }
                    updateJsonVisCacheElement(
                      sessionCacheForVaasVisulalisation,
                      nodeInformation.nodeId,
                      nodeInformation
                    );
                  }
                } //endIf nodeInformation != undefined
              });
              sessionStorage.setItem(
                "vaasInVisulationList",
                JSON.stringify(sessionCacheForVaasVisulalisation)
              );
              deferredMain.resolve();
              return;
            }
            //Only continue after this point if vaasAction = showOnly (1)

            inputData.objects = selectedObject;
            //Once the data Model is updated on V-Cloud we can directly get the DoKu Id from g4b_PEP_DOKID. So we do not need to inflate cpd0source_object for t4b_comp_DOKU_ID and save server call.
            inputData.attributes = [
              "cpd0design_element_id",
              "g4b_PEP_DOKID",
              "mdl0absolute_transform",
            ];
            //console.log("input data->", inputData)
            soaSvc
              .post("Core-2006-03-DataManagement", "getProperties", inputData)
              .then(function () {
                console.log(vaasPopupWindow);
                for (let i = 0; i < selectedObject.length; i++) {
                  if (selectedObject[i] == null) {
                    continue;
                  }
                  if (
                    selectedObject[i].props.cpd0design_element_id.dbValues[0] !=
                      null &&
                    selectedObject[i].props.mdl0absolute_transform.dbValues !=
                      null &&
                    selectedObject[i].props.g4b_PEP_DOKID.dbValues[0] != null
                  ) {
                    vaasInput = {
                      elementId:
                        selectedObject[i].props.cpd0design_element_id
                          .dbValues[0],
                      transformMatrix:
                        selectedObject[i].props.mdl0absolute_transform.dbValues,
                      dokuId: selectedObject[i].props.g4b_PEP_DOKID.dbValues[0],
                      nodeId: "",
                    };
                    console.log("VaasInput->", vaasInput);
                    try {
                      vaasInput.nodeId = vaasPopupWindow.webvis.add(
                        "urn:bmw:prisma:dokuid:" + vaasInput.dokuId
                      );

                      vaasPopupWindow.webvis.setProperty(
                        vaasInput.nodeId,
                        "localTransform",
                        vaasInput.transformMatrix
                      );
                      vaasPopupWindow.webvis.setProperty(
                        vaasInput.nodeId,
                        "enabled",
                        1
                      );

                      let vaasObject = {
                        nodeId: vaasInput.nodeId,
                        dokuId: vaasInput.dokuId,
                        transformMatrix: vaasInput.transformMatrix.toString(),
                        value: vaasInput.nodeId,
                        uid: designElementUIDs[i],
                        visibility: true,
                      };
                      sessionCacheForVaasVisulalisation.push(vaasObject);
                    } catch (ex) {
                      console.error(
                        "Error when sending Design Element data to webvis!"
                      );
                    }
                  }
                }
                sessionStorage.setItem(
                  "vaasInVisulationList",
                  JSON.stringify(sessionCacheForVaasVisulalisation)
                );
                deferredMain.resolve();
              });
          });
      });
    });
};

export let sendSelectionToVaaS = function (selectionUids, annotationsonly) {
  let selections = cdm.getObjects(selectionUids);
  let nodeId;
  let inputData = {};

  if (annotationsonly == undefined) {
    annotationsonly = false;
  }

  if (vaasPopupWindow == null || array_DesignElementsToVaaS == null) {
    return;
  }

  let arrPartUsages = [];
  for (let i = 0; i < selections.length; i++) {
    arrPartUsages.push(cdm.getObject(selections[i].props.partUsageUid));
  }

  inputData.objects = selections;
  inputData.attributes = [
    "object_string",
    "g4b_PEP_DOKID",
    "mdl0absolute_transform",
  ];
  soaSvc
    .post("Core-2006-03-DataManagement", "getProperties", inputData)
    .then(function (response) {
      vaasPopupWindow.externalSelection = true; //set external selection state in PDMVis window
      //TODO: Instead of nuking the entire selection state, refactor this piece to only select the differences
      let webvisSelections = vaasPopupWindow.webvis.getSelectedNodes();
      let selectionsToRemove = Array.from(webvisSelections);
      for (let k = 0; k < selections.length; k++) {
        nodeId = getNodeIDbyDokuIDandTransformMatrix(
          selections[k].props.g4b_PEP_DOKID.dbValues[0],
          selections[k].props.mdl0absolute_transform.dbValues.toString()
        );
        let idX = selectionsToRemove.indexOf(nodeId);
        if (idX != -1) {
          selectionsToRemove.splice(idX, 1);
        }
      }

      let selectionsToAdd = Array.from(selections);
      for (let k = 0; k < selections.length; k++) {
        nodeId = getNodeIDbyDokuIDandTransformMatrix(
          selections[k].props.g4b_PEP_DOKID.dbValues[0],
          selections[k].props.mdl0absolute_transform.dbValues.toString()
        );
        if (webvisSelections.indexOf(nodeId) != -1) {
          selectionsToAdd.splice(selectionsToAdd.indexOf(selections[k]), 1);
        }
      }
      //Remove the remaining selections from Webvis
      vaasPopupWindow.webvis.removeFromSelection(selectionsToRemove);

      for (let i = 0; i < selectionsToAdd.length; i++) {
        if (
          selectionsToAdd[i].props.object_string == undefined ||
          selectionsToAdd[i].props.g4b_PEP_DOKID == undefined ||
          selectionsToAdd[i].props.mdl0absolute_transform == undefined
        ) {
          // eslint-disable-next-line no-undef
          inputData.objects = [selectionsToAdd[i]];
          inputData.attributes = [
            "object_string",
            "g4b_PEP_DOKID",
            "mdl0absolute_transform",
          ];
          soaSvc
            .post("Core-2006-03-DataManagement", "getProperties", inputData)
            .then(function (response) {
              nodeId = getNodeIDbyDokuIDandTransformMatrix(
                selectionsToAdd[i].props.g4b_PEP_DOKID.dbValues[0],
                selectionsToAdd[
                  i
                ].props.mdl0absolute_transform.dbValues.toString()
              );
              if (nodeId == undefined) {
                console.log(
                  "nodeId not found for the selected Design Element."
                );
              } else {
                try {
                  if (!annotationsonly) {
                    console.log(
                      "Trying to send the following Node ID & DE ID to VaaS:" +
                        nodeId +
                        " " +
                        selectionsToAdd[i].props.object_string.dbValues[0]
                    );
                    if (webvisSelections.indexOf(nodeId) == -1) {
                      vaasPopupWindow.webvis.addToSelection(nodeId, false);
                    }
                  }

                  if (appCtxService.getCtx("vaasAnnotationsEnabled") == true) {
                    inputData = {};
                    inputData.attributes = ["g4b_VARIANT_FORMULA"];
                    inputData.objects = [arrPartUsages[i]];
                    let j = i;
                    soaSvc
                      .post(
                        "Core-2006-03-DataManagement",
                        "getProperties",
                        inputData
                      )
                      .then(function (response) {
                        if (
                          arrPartUsages[j].props.g4b_VARIANT_FORMULA != null &&
                          arrPartUsages[j].props.g4b_VARIANT_FORMULA != ""
                        ) {
                          selectionsToAdd[j].props.g4b_PV_VARIANT_FORMULA =
                            arrPartUsages[j].props.g4b_VARIANT_FORMULA;
                        }
                        g4b_toggleVaaSAnnotationsService.createAnnotation(
                          nodeId,
                          selectionsToAdd[j]
                        );
                      });
                  }
                } catch (ex) {
                  console.log(
                    "Would have sent the following node ID & DE ID to VaaS:" +
                      nodeId +
                      " " +
                      selectionsToAdd[i].props.object_string.dbValues[0]
                  );
                }
              }
            });
        } else if (
          selectionsToAdd[i].props.object_string != undefined &&
          selectionsToAdd[i].props.g4b_PEP_DOKID.dbValues[0] != null &&
          selectionsToAdd[i].props.mdl0absolute_transform.dbValues != null
        ) {
          nodeId = getNodeIDbyDokuIDandTransformMatrix(
            selectionsToAdd[i].props.g4b_PEP_DOKID.dbValues[0],
            selectionsToAdd[i].props.mdl0absolute_transform.dbValues.toString()
          );
          if (nodeId == undefined) {
            console.log("nodeId not found for the selected Design Element.");
          } else {
            try {
              if (!annotationsonly) {
                console.log(
                  "Trying to send the following Node ID & DE ID to VaaS:" +
                    nodeId +
                    " " +
                    selectionsToAdd[i].props.object_string.dbValues[0]
                );
                if (webvisSelections.indexOf(nodeId) == -1) {
                  vaasPopupWindow.webvis.addToSelection(nodeId, false);
                }
              }
              if (appCtxService.getCtx("vaasAnnotationsEnabled") == true) {
                inputData = {};
                inputData.attributes = [
                  "g4b_VARIANT_FORMULA",
                  "g4b_IN_VARIANT_FORMULA",
                ];
                inputData.objects = [arrPartUsages[i]];
                let j = i;
                soaSvc
                  .post(
                    "Core-2006-03-DataManagement",
                    "getProperties",
                    inputData
                  )
                  .then(function (response) {
                    if (
                      arrPartUsages[j].props.g4b_VARIANT_FORMULA != null &&
                      arrPartUsages[j].props.g4b_VARIANT_FORMULA != ""
                    ) {
                      selectionsToAdd[j].props.g4b_PV_VARIANT_FORMULA =
                        arrPartUsages[j].props.g4b_VARIANT_FORMULA;
                    }
                    g4b_toggleVaaSAnnotationsService.createAnnotation(
                      nodeId,
                      selectionsToAdd[j]
                    );
                  });
              }
            } catch (ex) {
              console.log(
                "Would have sent the following node ID & DE ID to VaaS:" +
                  nodeId +
                  " " +
                  selectionsToAdd[i].props.object_string.dbValues[0]
              );
            }
          }
        }
      }
      vaasPopupWindow.externalSelection = false; //reset external selection state in PDMVis window
    });
};

let factoryInitialize_g4b_PDMVisService = function () {
  //set parameters for endIndex and cursorData
  if (appCtxService.getCtx("isIframeVisible") == undefined) {
    appCtxService.registerCtx("isIframeVisible", false);
  } else {
    appCtxService.updateCtx("isIframeVisible", false);
  }

  eventBus.subscribe(
    "gwt.SubLocationContentSelectionChangeEvent",
    function () {
      //console.log("eventBus.subscribe");

      //This event can be triggered multiple times, so I'm using a global variable (boo!) to handle this
      if (handlingAWSelectionEvent) {
        return;
      }

      if (selectionEventTimeout != null) {
        clearTimeout(selectionEventTimeout);
      }

      handlingAWSelectionEvent = true;

      selectionEventTimeout = setTimeout(function () {
        let selectedObjectsArray = appCtxService.getCtx("mselected");
        if (selectedObjectsArray.length > 0) {
          let arrMultiselect = [];
          if (
            selectedObjectsArray[0].type === "Fgd0DesignElement" ||
            selectedObjectsArray[0].type === "G4B_AbsDesignElement"
          ) {
            for (let object in selectedObjectsArray) {
              if (
                object.props.awb0UnderlyingObject.dbValue != null &&
                object.props.awb0UnderlyingObject.dbValue != ""
              ) {
                arrMultiselect.push(object.props.awb0UnderlyingObject.dbValue);
              }
            }
            exports.sendSelectionToVaaS(arrMultiselect);
          }
          if (
            selectedObjectsArray[0].type === "Fgb0PartUsage" ||
            selectedObjectsArray[0].type === "G4B_PartUsage"
          ) {
            //If selected object is a G4B_PartUsage, get Design Element first
            let inputData = {};
            inputData.attributes = [
              "fgb0DesignComponent",
              "object_string",
              "g4b_PEP_DOKID",
              "mdl0absolute_transform",
              "g4b_VARIANT_FORMULA",
            ];
            inputData.objects = selectedObjectsArray;
            let partUsageUid;
            soaSvc
              .post("Core-2006-03-DataManagement", "getProperties", inputData)
              .then(function (response) {
                for (let object in response.modelObjects) {
                  if (
                    response.modelObjects[object].type === "G4B_DesignElement"
                  ) {
                    arrMultiselect.push(object);

                    if (
                      appCtxService.getCtx("vaasAnnotationsEnabled") == true
                    ) {
                      for (let object2 in response.modelObjects) {
                        if (
                          response.modelObjects[object2].uid.indexOf(
                            "bom0DesignElement:" + object
                          ) !== -1
                        ) {
                          partUsageUid = response.modelObjects[
                            object2
                          ].uid.substring(
                            response.modelObjects[object2].uid.indexOf(
                              ":",
                              response.modelObjects[object2].uid.indexOf(
                                "bom0PartUsage:"
                              )
                            ) + 1,
                            response.modelObjects[object2].uid.indexOf(
                              ",",
                              response.modelObjects[object2].uid.indexOf(
                                "bom0PartUsage:"
                              )
                            )
                          );
                          break;
                        }
                      }
                      response.modelObjects[object].props.partUsageUid =
                        partUsageUid;
                    }
                  }
                }
                exports.sendSelectionToVaaS(arrMultiselect);
              });
          }
          handlingAWSelectionEvent = false;

          try {
            if (vaasPopupWindow == null) {
              //console.log("eventBus.subscribe");
              if (appCtxService.getCtx("isIframeVisible") == undefined) {
                appCtxService.registerCtx("isIframeVisible", false);
                //console.log("eventBus.subscribe If");
              } else {
                appCtxService.updateCtx("isIframeVisible", false);
                //console.log("eventBus.subscribe Else");
              }
            }
          } catch (ex) {
            console.log(ex);
          }
        }
      }, 1250);
    },
    "g4b_PDMVisService"
  );
};

export let stopVisualizationInVaaS = function () {
  if (appCtxService.getCtx("g4b_forceStopPDMVisByUserFlag") == undefined) {
    appCtxService.registerCtx("g4b_forceStopPDMVisByUserFlag", true);
  } else {
    appCtxService.updateCtx("g4b_forceStopPDMVisByUserFlag", true);
  }
};

factoryInitialize_g4b_PDMVisService();

exports = {
  sendSelectionToVaaS,
  visualizeMultipleDesignElement,
  createInputForOccuranceForWorkset,
  getAllProductsUnderContext6,
  getPartitionSchemeUID_StaticProduct,
  stopVisualizationInVaaS,
};

export default exports;
