// @<COPYRIGHT>@
// ==================================================
// Copyright 2020.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/**
 * Simple Alert service for sample command Handlers
 *
 * @module js/g4b_TaKoSaveAsOverlayService
 */

import eventBus from "js/eventBus";
import cdm from "soa/kernel/clientDataModel";
import soaSvc from "soa/kernel/soaService";
import appCtxSvc from "js/appCtxService";
import $ from "jquery";
import dmSvc from "soa/dataManagementService";
import g4bCreatePrototypeSvc from "js/G4B_CreatePrototypeService";
import AwPromiseService from "js/awPromiseService";

let exports = {};

export let loadSubsetProperty = function () {
  dmSvc
    .getProperties(
      [
        {
          uid: appCtxSvc.getCtx(
            "selected.props.awb0UnderlyingObject.dbValues[0]"
          ),
          type: appCtxSvc.getCtx(
            "selected.props.awb0UnderlyingObjectType.dbValues[0]"
          ),
        },
      ],
      ["g4b_TaKoBasedOnSubset"]
    )
    .then(function () {
      appCtxSvc.registerCtx(
        "g4b_underlyingSubsetObject",
        cdm.getObject(
          appCtxSvc.getCtx("selected.props.awb0UnderlyingObject.dbValues[0]")
        )
      );
      //console.log('BasedOn Prop loaded');
      //eventBus.publish('g4b_TaKoSaveAsReviseWorkSpaceAdaptSuccessful');
    });
};

export let setOverlayPropertiesTaKoSubset = function (createdObj, data) {
  let baseObjUid = appCtxSvc.getCtx(
    "selected.props.awb0UnderlyingObject.dbValues[0]"
  );
  let baseObj = cdm.getObject(baseObjUid);

  if (data.g4bCreateOverlayCheckBox.dbValue == true) {
    //inputData1 for setting the properties on overlay subset
    let inputData1 = {
      objects: [createdObj],
      attributes: {
        g4b_TaKoSubsetType: {
          stringVec: ["2"],
        },
        g4b_TaKoBasedOnSubset: {
          stringVec: [baseObjUid],
        },
      },
    };

    //inputData2 for setting the properties on base subset
    let inputData2 = {
      objects: [baseObj],
      attributes: {
        g4b_TaKoSubsetType: {
          stringVec: ["1"],
        },
        g4b_TaKoBasedOnSubset: {
          stringVec: [createdObj.uid],
        },
      },
    };

    let promise1 = soaSvc.post(
      "Core-2007-01-DataManagement",
      "setProperties",
      inputData1
    );
    let promise2 = soaSvc.post(
      "Core-2007-01-DataManagement",
      "setProperties",
      inputData2
    );

    $.when(promise1, promise2)
      .done()
      .then(function () {
        // eslint-disable-next-line no-console
        console.log("Subsets- overlay and base properties added");
        eventBus.publish("primaryWorkarea.reset", {});
        eventBus.publish("cdm.relatedModified", {
          relatedModified: [appCtxSvc.getCtx("occmgmtContext.topElement")],
          refreshLocationFlag: true,
          relations: "",
        });
      });
  } else {
    let inputData = {
      objects: [createdObj],
      attributes: {
        g4b_TaKoSubsetType: {
          stringVec: [],
        },
        g4b_TaKoBasedOnSubset: {
          stringVec: [],
        },
      },
    };
    soaSvc
      .post("Core-2007-01-DataManagement", "setProperties", inputData)
      .then(function () {
        // eslint-disable-next-line no-console
        console.log("Subset Updated");
        eventBus.publish("primaryWorkarea.reset", {});
        eventBus.publish("cdm.relatedModified", {
          relatedModified: [appCtxSvc.getCtx("occmgmtContext.topElement")],
          refreshLocationFlag: true,
          relations: "",
        });
      });
  }

  //Create subscription to apply MMG-FB partition scheme to newly created subset
  let pciChangedSubscription = eventBus.subscribe(
    "productContextChangedEvent",
    function () {
      let getPartitionSchemeUidPromise =
        g4bCreatePrototypeSvc.getPartitionSchemeUid("MMG-FB", createdObj.uid);
      $.when(getPartitionSchemeUidPromise).done(function (partitionScheme) {
        let applyPartitionSchemeToSubsetPromise =
          g4bCreatePrototypeSvc.replaySubsetRecipe(
            createdObj.uid,
            partitionScheme,
            true
          );
        $.when(applyPartitionSchemeToSubsetPromise).done(function () {
          eventBus.unsubscribe(pciChangedSubscription);
          eventBus.publish("primaryWorkarea.reset", {});
          eventBus.publish("cdm.relatedModified", {
            relatedModified: [appCtxSvc.getCtx("selected")],
            refreshLocationFlag: true,
            relations: "",
          });
        });
      });
    }
  );
};

export let removeSubsetProperties = function () {
  let deferred = AwPromiseService.instance.defer();
  if (
    appCtxSvc.getCtx("selected.props.awb0UnderlyingObjectType.dbValues[0]") ===
      "Cpd0DesignSubsetElement" &&
    appCtxSvc.getCtx(
      "occmgmtContext.topElement.props.awb0UnderlyingObjectType.dbValues[0]"
    ) === "G4B_ProtTypeSpecRevision"
  ) {
    let selectedObjUid = appCtxSvc.getCtx(
      "selected.props.awb0UnderlyingObject.dbValues[0]"
    );
    let selectedObj = cdm.getObject(selectedObjUid);

    //fetch based-on object
    let otherSideObjUid = selectedObj.props.g4b_TaKoBasedOnSubset.dbValues[0];
    if (otherSideObjUid != null) {
      let otherSideObj = cdm.getObject(otherSideObjUid);
      //We need to empty the 'g4b_TaKoBasedOnSubset' and 'g4b_TaKoSubsetType' properties before deleting subset
      let inputData1 = {
        objects: [selectedObj],
        attributes: {
          g4b_TaKoSubsetType: {
            stringVec: [],
          },
          g4b_TaKoBasedOnSubset: {
            stringVec: [],
          },
        },
      };

      let inputData2 = {
        objects: [otherSideObj],
        attributes: {
          g4b_TaKoSubsetType: {
            stringVec: [],
          },
          g4b_TaKoBasedOnSubset: {
            stringVec: [],
          },
        },
      };

      let promise1 = soaSvc.post(
        "Core-2007-01-DataManagement",
        "setProperties",
        inputData1
      );
      let promise2 = soaSvc.post(
        "Core-2007-01-DataManagement",
        "setProperties",
        inputData2
      );

      $.when(promise1, promise2)
        .done()
        .then(function () {
          // eslint-disable-next-line no-console
          console.log("Subsets- overlay and base properties removed");
          deferred.resolve();
          //eventBus.publish( 'g4b_TaKoAwp0DeleteCommandHmm');
        });
    } else {
      deferred.resolve();
    }
  } else {
    deferred.resolve();
  }
  return deferred.promise;
};
exports = {
  removeSubsetProperties,
  setOverlayPropertiesTaKoSubset,
  loadSubsetProperty,
};

export default exports;
