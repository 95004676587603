/**
 * @module js/VIMTableFilterService
 * @author Sunil Petkar
 *
 * This service deals with the VDAM configuation objects table and  issue tabel of FTM issue conatner
 * It constructs the ViewModelProperties to show
 *
 * ModifyBy                 ModifyOn            Comment
 * Sunil Petkar           21 Dec 2023       Initial
 *
 */
import app from "app";
import AwPromiseService from "js/awPromiseService";
import appCtxSvc from "js/appCtxService";
import _ from "lodash";
import eventBus from "js/eventBus";
import messagingService from "js/messagingService";
import localeService from "js/localeService";
import VIMSoaUtils from "js/VIMSoaUtils";
("use strict");
let exports = {};

let isFilterapplied = false;
let isSortApplied = false;
let selectedObjects=[];

let getTypeOfSelectedParentObjCtxNames = function () {
  let xrtSummaryContextObject = appCtxSvc.getCtx("xrtSummaryContextObject");
  let typeOfSelectedParentObj = xrtSummaryContextObject.type;
  let infoStructureObject;
  if (typeOfSelectedParentObj === "V4B_OrderRequestRevision") {
    infoStructureObject = {
      savedFilterCtxName: "vimCtx.VDAMConfigrationTableFilter",
      isFilterAppliedCtxName:
        "vimCtx.V4B_isVDAMConfigrationColumnFilterApplied",
      selectedParentObjType: "V4B_OrderRequestRevision",
      relationType: "V4B_HasOrdReqToConfig_rel",
      savedFilterCtxNameToDelete: "VDAMConfigrationTableFilter",
      isFilterAppliedCtxNameToDelete:
        "V4B_isVDAMConfigrationColumnFilterApplied",
      localeTextBundle: localeService.getLoadedText(
        app.getBaseUrlPath() + "/i18n/VDAMLocationsMessages"
      ),
      preferencesName: "V4B_VDAM_USER_SAVED_FILTER",
      preferencesNameToGetValue: "preferences.V4B_VDAM_USER_SAVED_FILTER",
      grid: "getConfigurationsGrid",
      columnProvider: "GetConfigurationsColumnProvider",
    };
  }
  if (typeOfSelectedParentObj === "V4B_VIMContainerRevision") {
    infoStructureObject = {
      savedFilterCtxName: "vimCtx.SavedIssueTableFilters",
      isFilterAppliedCtxName: "vimCtx.isIssueTableColumnFilterApplied",
      selectedParentObjType: "V4B_VIMContainerRevision",
      relationType: "V4B_Container_VIM_FTM_Rel",
      savedFilterCtxNameToDelete: "SavedIssueTableFilters",
      isFilterAppliedCtxNameToDelete: "isIssueTableColumnFilterApplied",
      localeTextBundle: localeService.getLoadedText(
        app.getBaseUrlPath() + "/i18n/FTMLocationsMessage"
      ),
      preferencesName: "V4B_VCON_USER_SAVED_FILTER",
      preferencesNameToGetValue: "preferences.V4B_VCON_USER_SAVED_FILTER",
      grid: "vimContainerIssueGrid",
      columnProvider: "IssueColumnProvider",
    };
  }
  return infoStructureObject;
};
let getOprationLocalName = function (operation) {
  let operationLocal;
  let _localeTextBundle = localeService.getLoadedText(
    app.getBaseUrlPath() + "/i18n/UIMessages"
  );
  switch (operation) {
    case "gt":
      operationLocal = _localeTextBundle.greaterThanEqualsFilterTooltip;
      break;
    case "lt":
      operationLocal = _localeTextBundle.lessThanEqualsFilterTooltip;
      break;
    case "equals":
      operationLocal = _localeTextBundle.equalsFilterTooltip;
      break;
    case "range":
      operationLocal = _localeTextBundle.rangeOperation;
      break;
    case "notequals":
      operationLocal = _localeTextBundle.notEqualsFilterTooltip;
      break;
    case "contains":
      operationLocal = _localeTextBundle.containsFilterTooltip;
      break;
    case "caseSensitiveEquals":
      operationLocal = _localeTextBundle.equalsFilterTooltip;
      break;
    case "caseSensitiveNotEquals":
      operationLocal = _localeTextBundle.notEqualsFilterTooltip;
      break;
  }
  return operationLocal;
};
let getAppliedFilterResultInfo = function (
  columnFilter,
  infoSelectedParentObject,
  totalFilterObjects
) {
  let serachStr = "";
  let localeTextBundle = infoSelectedParentObject.localeTextBundle;
  let uiMessageLocal = localeService.getLoadedText(
    app.getBaseUrlPath() + "/i18n/UIMessages"
  );
  columnFilter.forEach(function (filterValue) {
    let localNameOfColumn = localeTextBundle[filterValue.columnName];
    let operation = getOprationLocalName(filterValue.operation);
    let filterVal = filterValue.values.join(",");
    if (localNameOfColumn == undefined) {
      let localTextKeys = Object.keys(localeTextBundle);
      localTextKeys.forEach((key) => {
        if (key.includes(filterValue.columnName)) {
          localNameOfColumn = localeTextBundle[key];
        }
      });
    }
    if (serachStr === "") {
      serachStr =
        '"' + localNameOfColumn + '" ' + operation + ' "' + filterVal + '"';
    } else {
      serachStr =
        serachStr +
        " " +
        uiMessageLocal.andFilterTooltip +
        ' "' +
        localNameOfColumn +
        '" ' +
        operation +
        ' "' +
        filterVal +
        '"';
    }
  });
  //results found for
  let local_v4b_filter_Applied = localeTextBundle["v4b_filter_Applied"];
  let local_v4b_filter_Applied_result =
    localeTextBundle["v4b_filter_Applied_result"];
  return (
    totalFilterObjects +
    local_v4b_filter_Applied +
    serachStr +
    local_v4b_filter_Applied_result
  );
};
/**
 * This function return filtered list object
 * @param {*} data
 * @param {*} columnFilters
 * @param {*} sortCriteria
 * @returns
 */
export let getfilteredAppliedOnCustomTable = function (
  inPutIssueList,
  columnFilters,
  sortCriteria
) {
  let localColumnFilter = columnFilters;
  let filteredConfigObjList = "";
  let infoSelectedParentObject = getTypeOfSelectedParentObjCtxNames();
  if (
    infoSelectedParentObject.selectedParentObjType ===
    "V4B_OrderRequestRevision"
  ) {
    filteredConfigObjList = inPutIssueList;
  }
  if (
    infoSelectedParentObject.selectedParentObjType ===
    "V4B_VIMContainerRevision"
  ) {
    filteredConfigObjList = inPutIssueList;
  }

  let savedFilterPreferncevalue = appCtxSvc.getCtx(
    infoSelectedParentObject.preferencesNameToGetValue
  );
  let prefSavedColumnFilters = [];
  if (savedFilterPreferncevalue !== undefined) {
    savedFilterPreferncevalue.forEach(function (preferenceValue) {
      if (
        preferenceValue !== "NoSavedFilters" &&
        preferenceValue.trim() !== ""
      ) {
        let columnFiltersSavedPrefvalue = {
          columnName: preferenceValue.split("#")[0],
          operation: preferenceValue.split("#")[1],
          values: preferenceValue.split("#")[2].split(","),
        };
        prefSavedColumnFilters.push(columnFiltersSavedPrefvalue);
      }
    });
  }

  console.log("saved pref value=" + savedFilterPreferncevalue);
  if (
    appCtxSvc.getCtx(infoSelectedParentObject.savedFilterCtxName) !== undefined
  ) {
    localColumnFilter = appCtxSvc.getCtx(
      infoSelectedParentObject.savedFilterCtxName
    );
    if (
      appCtxSvc.getCtx(infoSelectedParentObject.isFilterAppliedCtxName) ===
      undefined
    ) {
      appCtxSvc.updatePartialCtx(
        infoSelectedParentObject.isFilterAppliedCtxName,
        true
      );
    }
  } else if (
    prefSavedColumnFilters.length > 0 &&
    appCtxSvc.getCtx("vimCtx.v4b_clear_filter") === undefined
  ) {
    localColumnFilter = prefSavedColumnFilters;
    if (
      appCtxSvc.getCtx(infoSelectedParentObject.isFilterAppliedCtxName) ===
        undefined &&
      appCtxSvc.getCtx(infoSelectedParentObject.savedFilterCtxName) ===
        undefined
    ) {
      appCtxSvc.updatePartialCtx(
        infoSelectedParentObject.savedFilterCtxName,
        prefSavedColumnFilters
      );
      appCtxSvc.updatePartialCtx(
        infoSelectedParentObject.isFilterAppliedCtxName,
        true
      );
    }
  } else {
    console.log("no saved filter saved");
    localColumnFilter = [];
    if (
      appCtxSvc.getCtx(infoSelectedParentObject.isFilterAppliedCtxName) !==
      undefined
    ) {
      let localVimCtx = appCtxSvc.getCtx("vimCtx");
      if (
        localVimCtx.hasOwnProperty(
          infoSelectedParentObject.isFilterAppliedCtxNameToDelete
        )
      ) {
        delete localVimCtx[
          infoSelectedParentObject.isFilterAppliedCtxNameToDelete
        ];
      }
      appCtxSvc.updateCtx("vimCtx", localVimCtx);
    }
  }
  if (localColumnFilter && localColumnFilter.length > 0) {
    // Apply filtering
    _.forEach(localColumnFilter, function (columnFilter) {
      filteredConfigObjList = applyFilters(columnFilter, filteredConfigObjList);
    });
  }
  //added the below for loop without if chech of localColumnFilter to test the apply filter
  /* _.forEach(columnFilters, function (columnFilter) {
    filteredConfigObjList = applyFilters(columnFilter, filteredConfigObjList);
  });*/
  if(filteredConfigObjList === undefined){
    filteredConfigObjList=[];
  }
  let appliedFilterInfo = getAppliedFilterResultInfo(
    localColumnFilter,
    infoSelectedParentObject,
    filteredConfigObjList.length
  );
  //vimCtx.V4B_isVDAMConfigrationColumnFilterApplied
  appCtxSvc.updatePartialCtx("vimCtx.VimFilterApplied", appliedFilterInfo);
  filteredConfigObjList = applySort(
    sortCriteria,
    filteredConfigObjList,
    infoSelectedParentObject.selectedParentObjType
  );

  return AwPromiseService.instance.resolve(filteredConfigObjList);
};

let applyFilters = function (columnFilter, inputIssureRevs) {
  let filterValue = columnFilter.values[0];
  let propName = columnFilter.columnName;
  let propStr = "props." + propName + ".uiValues[0]";
  switch (columnFilter.operation) {
    case "contains":
      inputIssureRevs = inputIssureRevs.filter(function (issueRev) {
        let propVal = _.get(issueRev, propStr, "");
        return propVal.toLowerCase().includes(filterValue.toLowerCase());
      });
      break;
    case "equals":
      inputIssureRevs = inputIssureRevs.filter(function (issueRev) {
        let propVal = _.get(issueRev, propStr, "");
        return columnFilter.values.includes(propVal);
      });
      break;
    //change the GAUDI-11604 INTEG - Defect Filtering in VDAM table does not work ==> to fix issue chnage switch case "notEquals"=== "notequals"
    case "notequals":
      inputIssureRevs = inputIssureRevs.filter(function (issueRev) {
        let propVal = _.get(issueRev, propStr, "");
        return !columnFilter.values.includes(propVal);
      });
      break;
    case "notEquals":
      inputIssureRevs = inputIssureRevs.filter(function (issueRev) {
        let propVal = _.get(issueRev, propStr, "");
        return !columnFilter.values.includes(propVal);
      });
      break;
    case "lt":
      inputIssureRevs = inputIssureRevs.filter(function (issueRev) {
        let propVal = _.get(issueRev, propStr, "");
        let filterdVals = columnFilter.values.filter(
          (filterValue) => Number(propVal) < Number(filterValue)
        );
        return filterdVals.length > 0;
      });
      break;
    case "gt":
      inputIssureRevs = inputIssureRevs.filter(function (issueRev) {
        let propVal = _.get(issueRev, propStr, "");
        let filterdVals = columnFilter.values.filter(
          (filterValue) => Number(propVal) > Number(filterValue)
        );
        return filterdVals.length > 0;
      });
      break;
    case "range":
      inputIssureRevs = inputIssureRevs.filter(function (issueRev) {
        let propVal = _.get(issueRev, propStr, "");
        let fromValue = columnFilter.values[0];
        let toValue = columnFilter.values[1];
        let filterdVals = columnFilter.values.filter(function () {
          return (
            Number(propVal) >= Number(fromValue) &&
            Number(propVal) <= Number(toValue)
          );
        });
        return filterdVals.length > 0;
      });
      break;
    case "caseSensitiveEquals":
      inputIssureRevs = inputIssureRevs.filter(function (issueRev) {
        let propVal = _.get(issueRev, propStr, "");
        return columnFilter.values.includes(propVal);
      });
      break;
    case "caseSensitiveNotEquals":
      inputIssureRevs = inputIssureRevs.filter(function (issueRev) {
        let propVal = _.get(issueRev, propStr, "");
        return !columnFilter.values.includes(propVal);
      });
      break;
    default:
      break;
  }
  return inputIssureRevs;
};

let applySort = function (sortCriteria, issues, selectedParentObjType) {
  isSortApplied = true;
  if (sortCriteria && sortCriteria.length > 0 && issues.length > 0) {
    let criteria = sortCriteria[0];
    let sortDirection = criteria.sortDirection;
    let sortColName = criteria.fieldName;
    let propType = issues[0].props[sortColName].propertyDescriptor.valueType;

    if (sortDirection === "ASC") {
      issues.sort(function (a, b) {
        if (propType == 8 || propType == 9) {
          // 8= string prop
          if (
            a.props[sortColName].uiValues[0] <= b.props[sortColName].uiValues[0]
          ) {
            return -1;
          }
        } else if (propType == 5) {
          // 5 = integer prop
          if (
            Number(a.props[sortColName].uiValues[0]) <=
            Number(b.props[sortColName].uiValues[0])
          ) {
            return -1;
          }
        }
        return 1;
      });
    } else if (sortDirection === "DESC") {
      issues.sort(function (a, b) {
        if (propType == 8 || propType == 9) {
          // 8= string prop
          if (
            a.props[sortColName].uiValues[0] >= b.props[sortColName].uiValues[0]
          ) {
            return -1;
          }
        } else if (propType == 5) {
          // 5 = integer prop
          if (
            Number(a.props[sortColName].uiValues[0]) >=
            Number(b.props[sortColName].uiValues[0])
          ) {
            return -1;
          }
        }
        return 1;
      });
    }
  }
  return issues;
};
export let updateRelationContext = function (dataProvider) {
  let infoSelectedParentObject = getTypeOfSelectedParentObjCtxNames();
  if(isFilterapplied || isSortApplied)
    dataProvider.selectionModel.setSelection(selectedObjects);
  isFilterapplied = false;
  isSortApplied = false;
  let tableSelction = dataProvider.getSelectedObjects();
   selectedObjects = tableSelction;
   if (tableSelction.length > 0) {
     let relInfo = tableSelction.map((eachSel) => {
       return {
         primaryObject: appCtxSvc.getCtx('xrtSummaryContextObject'),
         secondaryObject: eachSel,
         relationType: infoSelectedParentObject.relationType,
       };
     });
     appCtxSvc.registerCtx("relationContext", {
       relationInfo: relInfo,
     });
   } else {
     appCtxSvc.unRegisterCtx("relationContext");
   }
};
export let updateFilterFacet = function (
  columnFilters,
  filteredIssues,
  filterFacetInput
) {
  let updateFilters = filterFacetInput.columnFilters;
  let columnName = filterFacetInput.column.field;
  let isSaveFilterCheck = false;
  let facetsToReturn = [];
  let infoSelectedParentObject = getTypeOfSelectedParentObjCtxNames();
  let facetValues = {
    values: [],
    totalFound: 0,
  };

  if (columnFilters) {
    _.forEach(columnFilters, function (columnFilter) {
      if (columnName !== columnFilter.columnName) {
        filteredIssues = applyFilters(columnFilter, filteredIssues);
      }
    });
  }

  if (updateFilters.length > 0) {
    if (
      appCtxSvc.getCtx(infoSelectedParentObject.savedFilterCtxName) !==
      undefined
    ) {
      let vDAmSavedColumnFilter = appCtxSvc.getCtx(
        infoSelectedParentObject.savedFilterCtxName
      );
      isSaveFilterCheck = true;
      _.forEach(vDAmSavedColumnFilter, function (columnFilter) {
        filteredIssues = applyFilters(columnFilter, filteredIssues);
      });
    } else {
      _.forEach(updateFilters, function (columnFilter) {
        filteredIssues = applyFilters(columnFilter, filteredIssues);
      });
    }
  }
  if (!isSaveFilterCheck) {
    if (
      appCtxSvc.getCtx(infoSelectedParentObject.savedFilterCtxName) !==
      undefined
    ) {
      let vDAmSavedColumnFilter = appCtxSvc.getCtx(
        infoSelectedParentObject.savedFilterCtxName
      );
      _.forEach(vDAmSavedColumnFilter, function (columnFilter) {
        filteredIssues = applyFilters(columnFilter, filteredIssues);
      });
    }
  }

  // prepare facets data
  _.forEach(filteredIssues, function (issue) {
    let propStr = "props." + columnName + ".uiValues[0]";
    let propVal = _.get(issue, propStr, "");
    facetsToReturn.push(propVal);
  });

  facetsToReturn = _.uniq(facetsToReturn);
  facetValues.values = facetsToReturn;
  facetValues.totalFound = facetsToReturn.length;
  return facetValues;
};

function removeFilter(saveFilter, filterValueTodelet) {
  return saveFilter.filter(function (nextFilterValue) {
    return nextFilterValue.columnName != filterValueTodelet;
  });
}

export let clearAllFilter = function (data) {
  var deferred = AwPromiseService.instance.defer();
  let infoSelectedParentObject = getTypeOfSelectedParentObjCtxNames();
  if (
    appCtxSvc.getCtx(infoSelectedParentObject.savedFilterCtxName) !== undefined
  ) {
    console.log("Remove all filter");
    let localVimCtx = appCtxSvc.getCtx("vimCtx");
    if (
      localVimCtx.hasOwnProperty(
        infoSelectedParentObject.isFilterAppliedCtxNameToDelete
      )
    ) {
      delete localVimCtx[
        infoSelectedParentObject.isFilterAppliedCtxNameToDelete
      ];
    }
    if (
      localVimCtx.hasOwnProperty(
        infoSelectedParentObject.savedFilterCtxNameToDelete
      )
    ) {
      let clearColumnFilters = appCtxSvc.getCtx(
        infoSelectedParentObject.savedFilterCtxName
      );
      if (clearColumnFilters && clearColumnFilters.length > 0)
        clearColumnFilters.forEach((column) => {
          eventBus.publish("pltable.columnFilterApplied", {
            gridId: infoSelectedParentObject.grid,
            columnName: column.columnName,
          });
        });
      delete localVimCtx[infoSelectedParentObject.savedFilterCtxNameToDelete];
    }
    localVimCtx.v4b_clear_filter = true;
    appCtxSvc.updateCtx("vimCtx", localVimCtx);
    eventBus.publish("plTable.clientRefresh");
    eventBus.publish("cdm.relatedModified", {
      relatedModified: [appCtxSvc.ctx.xrtSummaryContextObject],
      refreshLocationFlag: true,
    });
  }
  if (
    appCtxSvc.getCtx(infoSelectedParentObject.savedFilterCtxName) ===
      undefined &&
    appCtxSvc.getCtx(infoSelectedParentObject.preferencesNameToGetValue)[0] !==
      "NoSavedFilters"
  ) {
    let localVimCtx = appCtxSvc.getCtx("vimCtx");
    localVimCtx.v4b_clear_filter = true;
    appCtxSvc.updateCtx("vimCtx", localVimCtx);
    eventBus.publish("plTable.clientRefresh");
    eventBus.publish("cdm.relatedModified", {
      relatedModified: [appCtxSvc.ctx.xrtSummaryContextObject],
      refreshLocationFlag: true,
    });
  }
  let saveFilter = ["NoSavedFilters"];
  let saveFilterInPrefRes = saveAppliedFilterInPrefernce(
    infoSelectedParentObject.preferencesName,
    saveFilter,
    deferred
  );
  console.log("NoSavedFilters responce =" + saveFilterInPrefRes);
  return;
};

let saveAppliedFilterInPrefernce = function (
  preferencesName,
  savedFilter,
  deferred
) {
  let preferenceValues = [];
  if (savedFilter !== undefined) {
    savedFilter.forEach(function (eachSavedFilterValue) {
      if (eachSavedFilterValue === "NoSavedFilters") {
        preferenceValues.push(eachSavedFilterValue);
      } else {
        let columnName = eachSavedFilterValue.columnName;
        let operation = eachSavedFilterValue.operation;
        let values = eachSavedFilterValue.values;
        let prefValue = columnName + "#" + operation + "#" + values.join(",");
        preferenceValues.push(prefValue);
      }
    });
  }

  return VIMSoaUtils.setPreferencesAtLocations(
    "user",
    preferencesName,
    preferenceValues
  ).then(
    function (response) {
      VIMSoaUtils.refreshPreferences().then(function (response) {
        console.log("filter value updated");
      });
      return deferred.resolve(response);
    },
    function (errObj) {
      var msg = errObj;
      errObj && errObj.message && (msg = errObj.message);
      messagingService.showError(msg);
      return;
    }
  );
};
//Check nefilter value is already cased or not
let isInputFilterAlradySaved = function (newColumnFilter, savedFilter) {
  // Convert newColumnFilter object to JSON string for comparison
  const objString = JSON.stringify(newColumnFilter);
  // Check if the JSON string of the newfilter  exists in the savedFilter
  return savedFilter.some((item) => JSON.stringify(item) === objString);
};
export let saveFilterApplied = function (inputGrid, columnProvider) {
  let infoSelectedParentObject = getTypeOfSelectedParentObjCtxNames();
  if (
    inputGrid.gridid === "vimContainerIssueGrid" ||
    inputGrid.gridid === "getConfigurationsGrid"
  ) {
    let deferred = AwPromiseService.instance.defer();
    let saveFilter = [];
    let inputFilterValuesToSave;
    let saveFilterflag = false;
    if (
      columnProvider.columnFilters !== undefined &&
      columnProvider.columnFilters.length > 0
    ) {
      inputFilterValuesToSave = columnProvider.columnFilters;
      saveFilterflag = true;
    }
    if (
      appCtxSvc.getCtx(infoSelectedParentObject.savedFilterCtxName) == undefined
    ) {
      if (saveFilterflag) {
        //saveFilter.push(localColumnFilter);
        saveFilter = inputFilterValuesToSave;
        appCtxSvc.updatePartialCtx(
          infoSelectedParentObject.savedFilterCtxName,
          saveFilter
        );
      }
    } else {
      if (saveFilterflag) {
        saveFilter = appCtxSvc.getCtx(
          infoSelectedParentObject.savedFilterCtxName
        );
        inputFilterValuesToSave.forEach(function (newColumnFilter) {
          if (!isInputFilterAlradySaved(newColumnFilter, saveFilter)) {
            saveFilter.push(newColumnFilter);
          }
        });
        appCtxSvc.updatePartialCtx(
          infoSelectedParentObject.savedFilterCtxName,
          saveFilter
        );
      }
      if (
        inputGrid.gridContextPlaceholder.columnFilterInfo !== undefined &&
        !inputGrid.gridContextPlaceholder.columnFilterInfo.column.filter
          .isFilterApplied
      ) {
        //remove saved filter for perticular colum
        let columnName_delete_filter =
          inputGrid.gridContextPlaceholder.columnFilterInfo.column.field;
        let oldSavedFilterList = appCtxSvc.getCtx(
          infoSelectedParentObject.savedFilterCtxName
        );
        saveFilter = removeFilter(oldSavedFilterList, columnName_delete_filter);
        console.log("saved filter after remove one " + saveFilter);
        if (saveFilter.length > 0) {
          appCtxSvc.updatePartialCtx(
            infoSelectedParentObject.savedFilterCtxName,
            saveFilter
          );
        } else {
          //no filter applayed
          appCtxSvc.unRegisterCtx(infoSelectedParentObject.savedFilterCtxName);
          appCtxSvc.unRegisterCtx(
            infoSelectedParentObject.isFilterAppliedCtxName
          );
        }
      }
      if (
        inputGrid.gridContextPlaceholder.columnFilterInfo === undefined &&
        inputGrid.filteredConfigObjList !== undefined
      ) {
        if (
          appCtxSvc.getCtx(infoSelectedParentObject.savedFilterCtxName) !==
          undefined
        ) {
          console.log("Remove all filter");
          appCtxSvc.unRegisterCtx(infoSelectedParentObject.savedFilterCtxName);
          appCtxSvc.unRegisterCtx(
            infoSelectedParentObject.isFilterAppliedCtxName
          );
        }
      }
    }
    if (saveFilter.length > 0) {
      let saveFilterInPrefRes = saveAppliedFilterInPrefernce(
        infoSelectedParentObject.preferencesName,
        saveFilter,
        deferred
      );
      console.log("saveFilterInPrefRes=" + saveFilterInPrefRes);
    }
  }
};
/**
 *
 * @param {Object} data this function prepare the data for the Issue table
 */
 export let getContainerIssues = function (resp) {
  let vmoList = [];
  let infoSelectedParentObject = getTypeOfSelectedParentObjCtxNames();
  _.forEach(resp.ServiceData.modelObjects, function (element) {
    if (element.type === "V4B_FTMTKB_IssueRevision") {
      vmoList.push(element);
    }
  });

  //default: sort ascending by running number
  let sortCriteria = [
    {
      fieldName: "v4b_running_number",
      sortDirection: "ASC",
    },
  ];
  vmoList = applySort(sortCriteria, vmoList,infoSelectedParentObject.selectedParentObjType);
  return vmoList;
};

exports = {
  getContainerIssues,
  getfilteredAppliedOnCustomTable,
  updateRelationContext,
  updateFilterFacet,
  clearAllFilter,
  saveFilterApplied,
};

export default exports;
