// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwSublocation from 'viewmodel/AwSublocationViewModel';
import AwDataNavigator from 'viewmodel/AwDataNavigatorViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/occMgmtDuplicateViewService', () => import('js/occMgmtDuplicateViewService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "imports": [],
    "data": {
        "_duplicateLocation": {
            "useAutoBookmark": true,
            "contextKey": "occmgmtContext",
            "dataProviderName": "occDataProvider",
            "gridId": "occTreeDuplicateTable",
            "name": "com.siemens.splm.client.duplicate:DuplicateSublocation",
            "label": {
                "source": "/i18n/OccMgmtDuplicateConstants",
                "key": "occMgmtDuplicateTitle"
            },
            "requestPref": {
                "savedSessionMode": "ignore",
                "showMarkup": [
                    "false"
                ]
            },
            "clientScopeURI": "Awb0OccMgmtDuplicate",
            "nameToken": "com.siemens.splm.client.duplicate:DuplicateSublocation",
            "policy": "/policies/occMgmtPropertyPolicy.json",
            "viewBase": "OccMgmtDuplicate",
            "editContext": "occDataProvider",
            "selectionQueryParamKey": "c_uid",
            "editSupportParamKeys": [
                "c_uid",
                "o_uid",
                "spageId",
                "pci_uid"
            ],
            "viewModes": {
                "TreeView": {
                    "primaryWorkArea": "tree",
                    "secondaryWorkArea": false
                }
            },
            "context": {
                "commandContextParameters": [
                    "filter",
                    "recipe",
                    "uid"
                ],
                "search": {
                    "hideInContentSearchBox": true
                }
            },
            "breadcrumbConfig": {},
            "urlParams": {
                "selectionQueryParamKey": "c_uid",
                "openStructureQueryParamKey": "o_uid",
                "rootQueryParamKey": "uid",
                "productContextQueryParamKey": "pci_uid",
                "csidQueryParamKey": "c_csid",
                "secondaryPageIdQueryParamKey": "spageId",
                "topElementQueryParamKey": "t_uid",
                "pageIdQueryParamKey": "pageId",
                "recipeParamKey": "recipe",
                "subsetFilterParamKey": "filter"
            },
            "headerPreference": {
                "hideGlobalSearch": true,
                "hideLogo": true
            },
            "awSubLocationPref": {
                "hideNavigationArea": true,
                "hideRightWall": true,
                "hideFooter": true,
                "orientation": "HORIZONTAL"
            }
        },
        "contextKey": "occmgmtContext",
        "occContext": {
            "initialValues": {
                "isDuplicateEnabled": true,
                "configContext": {},
                "displayToggleOptions": {},
                "supportedFeatures": {},
                "productContextInfo": {},
                "currentState": {},
                "previousState": {},
                "transientRequestPref": {},
                "persistentRequestPref": {},
                "disabledFeatures": [],
                "viewKey": "occmgmtContext"
            },
            "meta": {}
        }
    },
    "actions": {
        "initializeDuplicateView": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "initializeOccContext"
                },
                {
                    "action": "initializeOccMgmtDuplicateView"
                }
            ]
        },
        "initializeOccMgmtDuplicateView": {
            "actionType": "JSFunction",
            "method": "initializeOccMgmtDuplicateView",
            "deps": "js/occMgmtDuplicateViewService",
            "inputData": {
                "subPanelContext": "{{data}}"
            },
            "outputData": {
                "ctx.modelObjectsToOpen": "result",
                "data.modelObjectsToOpen": "result"
            }
        },
        "initializeOccContext": {
            "actionType": "JSFunction",
            "method": "initializeOccContext",
            "deps": "js/occMgmtDuplicateViewService",
            "inputData": {
                "subPanelContext": "{{data}}"
            },
            "outputData": {
                "occContext": "result.occContext"
            }
        },
        "destroyOccmgmtDuplicateView": {
            "actionType": "JSFunction",
            "method": "destroyOccmgmtDuplicateView",
            "deps": "js/occMgmtDuplicateViewService",
            "inputData": {
                "subPanelContext": "{{data}}"
            }
        }
    },
    "lifecycleHooks": {
        "onInit": "initializeDuplicateView",
        "onUnmount": "destroyOccmgmtDuplicateView"
    },
    "ctx": {
        "aceActiveContext": {
            "key": "occmgmtContext"
        }
    },
    "props": {
        "sub-panel-context": {
            "type": "object"
        },
        "provider": {
            "type": "object"
        },
        "baseSelection": {
            "type": "object"
        },
        "occContext": {
            "type": "string"
        }
    },
    "_viewModelId": "OccMgmtDuplicate",
    "_uniqueViewModelId": "OccMgmtDuplicate"
};

    /*
    <aw-sublocation sub-panel-context="{provider:data._duplicateLocation,occContext:data.occContext}" sub-location-preference="data._duplicateLocation.awSubLocationPref">
    <div class="sw-row h-12 afx-content-background">
        <div class="sw-column w-12">
            <aw-data-navigator sub-panel-context="{provider:data._duplicateLocation, occContext:data.occContext,contextKey:data._duplicateLocation.contextKey, selectionQueryParamKey : data._duplicateLocation.selectionQueryParamKey }" provider="data._duplicateLocation" contextKey="data._duplicateLocation.contextKey" baseSelection="ctx.modelObjectsToOpen[0]"></aw-data-navigator>
        </div>
    </div>
</aw-sublocation>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwSublocation subPanelContext={AwParseService.instance( "{provider:data._duplicateLocation,occContext:fields.occContext}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} subLocationPreference={AwParseService.instance( "data._duplicateLocation.awSubLocationPref" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        <div className="sw-row h-12 afx-content-background">
            <div className="sw-column w-12">
                <AwDataNavigator subPanelContext={AwParseService.instance( "{provider:data._duplicateLocation, occContext:fields.occContext,contextKey:data._duplicateLocation.contextKey, selectionQueryParamKey : data._duplicateLocation.selectionQueryParamKey }" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} provider={AwParseService.instance( "data._duplicateLocation" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} contextkey="data._duplicateLocation.contextKey" baseselection="ctx.modelObjectsToOpen[0]">
                </AwDataNavigator>
            </div>
        </div>
    </AwSublocation>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;