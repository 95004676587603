// @<COPYRIGHT>@
// ==================================================
// Copyright 2020.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/*global
 define
 */

/**
 *
 * @module js/epManageProductViewsService
 */

import _ from 'lodash';
import browserUtils from 'js/browserUtils';
import cdm from 'soa/kernel/clientDataModel';
import epLoadService from 'js/epLoadService';
import epLoadInputHelper from 'js/epLoadInputHelper';
import { constants as _epLoadConstants } from 'js/epLoadConstants';
import fmsUtils from 'js/fmsUtils';
import saveInputWriterService from 'js/saveInputWriterService';
import epSaveService from 'js/epSaveService';
import soaService from 'soa/kernel/soaService';
import sessionCtxSvc from 'js/sessionContext.service';

'use strict';

const SNAPSHOT_OBJECT_TYPE = 'SnapShotViewData';
const PMI_INFO_KEY = 'StrucExpandClientControlInfo';
const PMI_TARGET_ID = 'PMITargetIds';
const PMI_DEFAULT_PV_ANCHOR_UID = 'DefaultPVAnchorUID';

/**
 * open in Visualisation
 * This creates a vvi file which can be opened in Standalone Vis application
 *
 */

export function openInVis( datasetsToShow, currentScope, selectedOperation, ebomRoot ) {
    const objsForVis = [];
    objsForVis.push( currentScope );

    const input = {
        info: [ { occurrencesList: objsForVis } ]
    };

    // This selectedPVs is an array of Product views which needs to be included in Standalone vis.
    const selectedPVs = datasetsToShow.filter( dataset => dataset.type === SNAPSHOT_OBJECT_TYPE );

    //Returns the vis context uid of the given dataset
    soaService.post( 'Visualization-2013-12-StructureManagement', 'createVisSCsFromBOMsWithOptions', input )
        .then( function( response ) {
            const output = response.output;
            if( output && output.length > 0 ) {
                const visContext = output[ 0 ].structureRecipe;

                const baseUid = visContext.uid;

                const idInfos = {
                    id: null,
                    idAdditionalInfo: {
                        BaseDoc_UID: baseUid,
                        BaseDocTransient: 'True'
                    },
                    item: null,
                    itemRev: null,
                    operation: 'Open'
                };

                if( selectedPVs === null || selectedPVs === undefined || selectedPVs.length === 0 ) {
                    idInfos.id = visContext;
                } else {
                    idInfos.id = selectedPVs[ 0 ];

                    for( const pvIndex of selectedPVs.keys() ) {
                        idInfos.idAdditionalInfo.NumSnapshots = ( pvIndex + 1 ).toString();
                        const key = 'VisDoc_UID_' + ( pvIndex + 1 ).toString();
                        idInfos.idAdditionalInfo[ key ] = selectedPVs[ pvIndex ].uid;
                    }
                }

                /**
                 * adding the data required to load PMIs consumed under selected operation
                 * Key = StrucExpandClientControlInfo
                 * Vaue = EBOM Root ; selected operation
                 */
                if( selectedOperation ) {
                    if( ebomRoot ) {
                        const pmiInfo = ebomRoot.uid + ';' + selectedOperation.uid + ';scope-' + currentScope.uid;
                        idInfos.idAdditionalInfo[ PMI_INFO_KEY ] = pmiInfo;
                    }

                    // Adding key for default selection in standalone vis
                    idInfos.idAdditionalInfo[ PMI_DEFAULT_PV_ANCHOR_UID ] = selectedOperation.uid;

                    let loadTypeInput = [];
                    const loadTypeforObj = epLoadInputHelper.getLoadTypeInputs( [ _epLoadConstants.GET_PROPERTIES ], selectedOperation.uid, [ 'bl_clone_stable_occurrence_id' ], '', [] );
                    loadTypeInput.push( loadTypeforObj[ 0 ] );

                    epLoadService.loadObject( loadTypeInput, false ).then( function( output ) {
                        // Adding bl_clone_stable_occurrence_id of selected operation
                        if( selectedOperation.props.bl_clone_stable_occurrence_id && selectedOperation.props.bl_clone_stable_occurrence_id.dbValues.length > 0 ) {
                            if( selectedOperation.props.bl_clone_stable_occurrence_id.dbValues[ 0 ] !== null ) {
                                idInfos.idAdditionalInfo[ PMI_TARGET_ID ] = selectedOperation.props.bl_clone_stable_occurrence_id.dbValues[ 0 ];
                            }
                        }
                        reviseCheckAndLaunchFile( selectedOperation, idInfos );
                    } );
                } else {
                    // BTO do not have selected operation it takes selected pert node.
                    reviseCheckAndLaunchFile( currentScope, idInfos );
                }
            }
        } );
}

function reviseCheckAndLaunchFile( selectedObject, additionaInfo ) {
    const saveInputWriter = saveInputWriterService.get();
    let relatedObjects = [];
    const selectedObj = cdm.getObject( selectedObject.uid );
    saveInputWriter.addReviseInput( selectedObj );
    relatedObjects.push( selectedObj );
    let resourceBundle = 'GraphicsMessages';
    epSaveService.saveChanges( saveInputWriter, true, relatedObjects, resourceBundle ).then( function( _output ) {
        createLaunchFile( additionaInfo ).then( function( fmsTkt ) {
            const fileName = fmsUtils.getFilenameFromTicket( fmsTkt );
            fmsUtils.openFile( fmsTkt, fileName );
        } );
    } );
}

function createLaunchFile( additionalInfo ) {
    const serverInfo = _getServerInfo();
    const userAgentInfo = _getUserAgentInfo();

    const idInfos = [];
    idInfos.push( additionalInfo );

    const input = {};
    input.idInfos = idInfos;
    input.serverInfo = serverInfo;
    input.userDataAgentInfo = userAgentInfo;

    return soaService.post( 'Visualization-2011-02-DataManagement', 'createLaunchFile', input )
        .then( function( response ) {
            return response.ticket;
        } );
}

function _getServerInfo() {
    const soaPath = browserUtils.getBaseURL() + 'tc/';
    const protocol = soaPath.substring( 0, soaPath.indexOf( '://', 0 ) );

    const serverInfo = {};

    serverInfo.protocol = protocol;
    serverInfo.hostpath = soaPath;
    serverInfo.servermode = 4;

    return serverInfo;
}

function _getUserAgentInfo() {
    const userAgentInfo = {};

    userAgentInfo.userApplication = sessionCtxSvc.getClientID();
    userAgentInfo.userAppVersion = sessionCtxSvc.getClientVersion();

    return userAgentInfo;
}

const exports = {
    openInVis
};

export default exports;
